import { gql } from "@apollo/client"

export const FIND_CURRENT_USER = gql`
	{
		findCurrentUser{
			id
			phone
			code
			email
			name
			points
		}
	}
`
export const FIND_UNIQUE_USER = gql`
	query(
		$where: UserWhereUniqueInput!
	){
		findUniqueUser(
			where: $where
		){
			id
			phone
			code
			email
			name
			points
			requests{
				id
				reservedAt
				status
				rating
				review
				comment
				people
				paymentId
				scheduleId
				price
				discount
				reserve
				cancel
				objectId
				userId
				schedule{
					id
					name
					price
					discount
					reserve
					cancel
					week
					closes
					days
					startAt
					endAt
					fullday
					status
					objectId
				}
				object{
					id
					name
					description
					note
					images
					videos
					location
					place
					address
					people
					peopleInfo
					bed
					bedInfo
					square
					booking
					rules
					heat
					comforts
					tags
					premium
					publish
					companyId
				}
			}
		}
	}
`
export const FIND_FIRST_USER = gql`
	query(
		$where: UserWhereInput
		$orderBy: [UserOrderByWithRelationInput]
		$cursor: UserWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [UserScalarFieldEnum]
	){
		findFirstUser(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		){
			id
			phone
			code
			email
			name
			points
			requests{
				id
				reservedAt
				status
				rating
				review
				comment
				people
				paymentId
				scheduleId
				price
				discount
				reserve
				cancel
				objectId
				userId
				schedule{
					id
					name
					price
					discount
					reserve
					cancel
					week
					closes
					days
					startAt
					endAt
					fullday
					status
					objectId
				}
				object{
					id
					name
					description
					note
					images
					videos
					location
					place
					address
					people
					peopleInfo
					bed
					bedInfo
					square
					booking
					rules
					heat
					comforts
					tags
					premium
					publish
					companyId
				}
			}
		}
	}
`
export const FIND_MANY_USER = gql`
	query(
		$where: UserWhereInput
		$orderBy: [UserOrderByWithRelationInput]
		$cursor: UserWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [UserScalarFieldEnum]
	){
		findManyUser(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		){
			id
			phone
			code
			email
			name
			points
			requests{
				id
				reservedAt
				status
				rating
				review
				comment
				people
				paymentId
				scheduleId
				price
				discount
				reserve
				cancel
				objectId
				userId
				schedule{
					id
					name
					price
					discount
					reserve
					cancel
					week
					closes
					days
					startAt
					endAt
					fullday
					status
					objectId
				}
				object{
					id
					name
					description
					note
					images
					videos
					location
					place
					address
					people
					peopleInfo
					bed
					bedInfo
					square
					booking
					rules
					heat
					comforts
					tags
					premium
					publish
					companyId
				}
			}
		}
	}
`
export const FIND_MANY_USER_COUNT = gql`
	query(
		$where: UserWhereInput
		$orderBy: [UserOrderByWithRelationInput]
		$cursor: UserWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [UserScalarFieldEnum]
	){
		findManyUserCount(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		)
	}
`
export const FIND_CURRENT_ADMIN = gql`
	{
		findCurrentAdmin{
			id
			login
			password
		}
	}
`
export const FIND_UNIQUE_ADMIN = gql`
	query(
		$where: AdminWhereUniqueInput!
	){
		findUniqueAdmin(
			where: $where
		){
			id
			login
			password
		}
	}
`
export const FIND_FIRST_ADMIN = gql`
	query(
		$where: AdminWhereInput
		$orderBy: [AdminOrderByWithRelationInput]
		$cursor: AdminWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [AdminScalarFieldEnum]
	){
		findFirstAdmin(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		){
			id
			login
			password
		}
	}
`
export const FIND_MANY_ADMIN = gql`
	query(
		$where: AdminWhereInput
		$orderBy: [AdminOrderByWithRelationInput]
		$cursor: AdminWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [AdminScalarFieldEnum]
	){
		findManyAdmin(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		){
			id
			login
			password
		}
	}
`
export const FIND_MANY_ADMIN_COUNT = gql`
	query(
		$where: AdminWhereInput
		$orderBy: [AdminOrderByWithRelationInput]
		$cursor: AdminWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [AdminScalarFieldEnum]
	){
		findManyAdminCount(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		)
	}
`
export const FIND_CURRENT_COMPANY = gql`
	{
		findCurrentCompany{
			id
			name
			objects{
				id
			}
		}
	}
`
export const FIND_UNIQUE_COMPANY = gql`
	query(
		$where: CompanyWhereUniqueInput!
	){
		findUniqueCompany(
			where: $where
		){
			id
			phone
			contact
			payment
			paymentCardNumber
			paymentType
			email
			name
			legal
			splitId
			comment
			coupons{
				id
				code
				companyId
			}
			objects{
				id
				name
				description
				note
				images
				images_720
				images_1024
				videos
				location
				place
				address
				people
				peopleInfo
				bed
				bedInfo
				square
				booking
				rules
				heat
				comforts
				tags
				premium
				publish
				companyId
				schedules{
					id
					name
					price
					discount
					reserve
					cancel
					week
					closes
					days
					startAt
					endAt
					fullday
					status
					objectId
				}
				requests{
					id
					reservedAt
					status
					rating
					review
					comment
					people
					paymentId
					scheduleId
					price
					discount
					reserve
					cancel
					objectId
					userId
				}
			}
		}
	}
`
export const FIND_FIRST_COMPANY = gql`
	query(
		$where: CompanyWhereInput
		$orderBy: [CompanyOrderByWithRelationInput]
		$cursor: CompanyWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [CompanyScalarFieldEnum]
	){
		findFirstCompany(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		){
			id
			phone
			contact
			payment
			paymentCardNumber
			paymentType
			email
			name
			legal
			splitId
			comment
			coupons{
				id
				code
				companyId
			}
			objects{
				id
				name
				description
				note
				images
				images_720
				images_1024
				videos
				location
				place
				address
				people
				peopleInfo
				bed
				bedInfo
				square
				booking
				rules
				heat
				comforts
				tags
				premium
				publish
				companyId
				schedules{
					id
					name
					price
					discount
					reserve
					cancel
					week
					closes
					days
					startAt
					endAt
					fullday
					status
					objectId
				}
				requests{
					id
					reservedAt
					status
					rating
					review
					comment
					people
					paymentId
					scheduleId
					price
					discount
					reserve
					cancel
					objectId
					userId
				}
			}
		}
	}
`
export const FIND_MANY_COMPANY = gql`
	query(
		$where: CompanyWhereInput
		$orderBy: [CompanyOrderByWithRelationInput]
		$cursor: CompanyWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [CompanyScalarFieldEnum]
	){
		findManyCompany(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		){
			id
			phone
			contact
			payment
			paymentCardNumber
			paymentType
			email
			name
			legal
			splitId
			comment
			coupons{
				id
				code
				companyId
			}
			objects{
				id
				name
				description
				note
				images
				images_720
				images_1024
				videos
				location
				place
				address
				people
				peopleInfo
				bed
				bedInfo
				square
				booking
				rules
				heat
				comforts
				tags
				premium
				publish
				companyId
				schedules{
					id
					name
					price
					discount
					reserve
					cancel
					week
					closes
					days
					startAt
					endAt
					fullday
					status
					objectId
				}
				requests{
					id
					reservedAt
					status
					rating
					review
					comment
					people
					paymentId
					scheduleId
					price
					discount
					reserve
					cancel
					objectId
					userId
				}
			}
		}
	}
`
export const FIND_MANY_COMPANY_COUNT = gql`
	query(
		$where: CompanyWhereInput
		$orderBy: [CompanyOrderByWithRelationInput]
		$cursor: CompanyWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [CompanyScalarFieldEnum]
	){
		findManyCompanyCount(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		)
	}
`
export const FIND_UNIQUE_OBJECT = gql`
	query(
		$where: ObjectWhereUniqueInput!
	){
		findUniqueObject(
			where: $where
		){
			id
			name
			description
			note
			images
			images_720
			images_1024
			videos
			location
			place
			address
			people
			peopleInfo
			bed
			bedInfo
			square
			booking
			rules
			heat
			rating
			comforts
			tags
			premium
			publish
			companyId
			favorite @client
			requests{
				id
				reservedAt
				status
				rating
				ratingAt
				review
				comment
				people
				paymentId
				scheduleId
				price
				discount
				reserve
				cancel
				objectId
				userId
			}
			schedules{
				id
				name
				price
				discount
				reserve
				cancel
				week
				closes
				days
				startAt
				endAt
				fullday
				visible
				status
				objectId
				requests{
					id
					reservedAt
					status
					rating
					review
					comment
					people
					paymentId
					scheduleId
					price
					discount
					reserve
					cancel
					objectId
					userId
					schedule{
						id
						name
						price
						discount
						reserve
						cancel
						week
						closes
						days
						startAt
						endAt
						fullday
						status
						objectId
					}
				}
			}
			company{
				id
				phone
				contact
				payment
				paymentCardNumber
				paymentType
				email
				name
				legal
				splitId
				comment
				coupons{
					id
					code
					companyId
				}
				CompanyBilling{
					id
					fullName
					shortName
					inn
					kpp
					ogrn
					zip
					city
					street
					email
					lastName
					firstName
					middleName
					phone
					account
					bankName
					bik
					correspAcc
					companyId
				}
			}
		}
	}
`
export const FIND_FIRST_OBJECT = gql`
	query(
		$where: ObjectWhereInput
		$orderBy: [ObjectOrderByWithRelationInput]
		$cursor: ObjectWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [ObjectScalarFieldEnum]
	){
		findFirstObject(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		){
			id
			name
			description
			note
			images
			images_720
			images_1024
			videos
			location
			place
			address
			people
			rating
			peopleInfo
			bed
			bedInfo
			square
			booking
			rules
			heat
			comforts
			tags
			premium
			publish
			companyId
			favorite @client
			schedules{
				id
				name
				price
				discount
				reserve
				cancel
				week
				closes
				days
				startAt
				endAt
				fullday
				status
				objectId
				requests{
					id
					reservedAt
					status
					rating
					review
					comment
					people
					paymentId
					scheduleId
					price
					discount
					reserve
					cancel
					objectId
					userId
				}
			}
			requests{
				id
				reservedAt
				status
				rating
				review
				comment
				people
				paymentId
				scheduleId
				price
				discount
				reserve
				cancel
				objectId
				userId
				schedule{
					id
					name
					price
					discount
					reserve
					cancel
					week
					closes
					days
					startAt
					endAt
					fullday
					status
					objectId
				}
			}
			company{
				id
				phone
				contact
				payment
				email
				name
				legal
				splitId
				comment
				coupons{
					id
					code
					companyId
				}
			}
		}
	}
`
export const FIND_MANY_OBJECT = gql`
	query(
		$where: ObjectWhereInput
		$orderBy: [ObjectOrderByWithRelationInput]
		$cursor: ObjectWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [ObjectScalarFieldEnum]
	){
		findManyObject(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		){
			id
			name
			images
			images_720
			location
			place
			address
			people
			bed
			square
			heat
			comforts
			tags
			premium
			favorite @client
			rating
			schedules{
				id
				price
				discount
				status
			}
			requests{
				id
				reservedAt
				rating
			}
		}
	}
`
export const FIND_MANY_OBJECT_MAIN = gql`
	query(
		$where: ObjectWhereInput
		$orderBy: [ObjectOrderByWithRelationInput]
		$cursor: ObjectWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [ObjectScalarFieldEnum]
	){
		findManyObject(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		){
			id
			name
			images
			images_720
			location
			place
			address
			people
			bed
			square
			heat
			comforts
			tags
			premium
			favorite @client
			rating
			schedules{
				id
				price
				discount
				status
			}
			requests{
				id
				reservedAt
				rating
			}
		}
	}
`
export const FIND_MANY_OBJECTS = gql`
	query(
		$where: ObjectWhereInput
		$orderBy: [ObjectOrderByWithRelationInput]
		$cursor: ObjectWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [ObjectScalarFieldEnum]
	){
		findManyObject(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		){
			id
			name
			images
			images_720
			location
			place
			address
			people
			bed
			square
			heat
			comforts
			tags
			premium
			rating
			favorite @client
			mapVipRating
			schedules{
				id
				price
				discount
				status
				reserve
				week
				closes
				days
				fullday
			}
			requests{
				id
				reservedAt
				rating
				ratingAt
				status
			}
		}
	}
`
export const FIND_MANY_OBJECT_COUNT = gql`
	query(
		$where: ObjectWhereInput
		$orderBy: [ObjectOrderByWithRelationInput]
		$cursor: ObjectWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [ObjectScalarFieldEnum]
	){
		findManyObjectCount(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		)
	}
`
export const FIND_UNIQUE_REQUEST = gql`
	query(
		$where: RequestWhereUniqueInput!
	){
		findUniqueRequest(
			where: $where
		){
			id
			createdAt
			reservedAt
			status
			rating
			ratingAt
			name
			phone
			email
			review
			comment
			people
			paymentId
			scheduleId
			price
			discount
			reserve
			cancel
			objectId
			userId
			pushingNotification
			statusChangeHistory
			schedule{
				id
				name
				price
				discount
				reserve
				cancel
				week
				closes
				days
				startAt
				endAt
				fullday
				status
				objectId
				object{
					id
					name
					description
					note
					images
					images_720
					images_1024
					videos
					location
					place
					address
					people
					peopleInfo
					bed
					bedInfo
					square
					booking
					rules
					heat
					comforts
					tags
					premium
					publish
					companyId
				}
			}
			object{
				id
				name
				description
				note
				images
				images_720
				images_1024
				videos
				location
				place
				address
				people
				peopleInfo
				bed
				bedInfo
				square
				booking
				rules
				heat
				comforts
				tags
				premium
				publish
				companyId
				schedules{
					id
					name
					price
					discount
					reserve
					cancel
					week
					closes
					days
					startAt
					endAt
					fullday
					status
					objectId
				}
				company{
					id
					phone
					contact
					payment
					paymentCardNumber
					paymentType
					email
					name
					legal
					splitId
					comment
					CompanyBilling{
						id
						fullName
						shortName
						inn
						kpp
						ogrn
						zip
						city
						street
						email
						lastName
						firstName
						middleName
						phone
						account
						bankName
						bik
						correspAcc
						companyId
					}
				}
			}
			user{
				id
				phone
				code
				email
				name
				points
			}
		}
	}
`
export const FIND_UNIQUE_REQUEST_FOR_RESERVATION = gql`
	query(
		$where: RequestWhereUniqueInput!
	){
		findUniqueRequest(
			where: $where
		){
			id
			createdAt
			reservedAt
			status
			rating
			ratingAt
			name
			phone
			email
			review
			comment
			people
			price
			schedule{
				id
				name
				price
				discount
				reserve
				startAt
				endAt
				status
			}
			object{
				id
				name
				description
				note
				images
				images_720
				address
				people
				peopleInfo
				bed
				bedInfo
				square
				booking
				rules
				heat
				comforts
				tags
				premium
				company{
					id
					phone
					contact
					payment
					paymentCardNumber
					paymentType
					email
					name
					legal
					splitId
					comment
				}
			}
		}
	}
`
export const FIND_UNIQUE_REQUEST_FOR_ADMIN = gql`
	query(
		$where: RequestWhereUniqueInput!
	){
		findUniqueRequest(
			where: $where
		){
			id
			createdAt
			reservedAt
			status
			rating
			ratingAt
			name
			phone
			email
			review
			comment
			people
			price
			discount
			reserve
			schedule{
				id
				price
				startAt
				endAt
				status
			}
			object{
				id
				name
				images
				images_720
				publish
				companyId
			}
		}
	}
`
export const FIND_FIRST_REQUEST = gql`
	query(
		$where: RequestWhereInput
		$orderBy: [RequestOrderByWithRelationInput]
		$cursor: RequestWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [RequestScalarFieldEnum]
	){
		findFirstRequest(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		){
			id
			createdAt
			reservedAt
			status
			rating
			ratingAt
			name
			phone
			email
			review
			comment
			people
			paymentId
			scheduleId
			price
			discount
			reserve
			cancel
			objectId
			userId
			pushingNotification
			statusChangeHistory
			schedule{
				id
				name
				price
				discount
				reserve
				cancel
				week
				closes
				days
				startAt
				endAt
				fullday
				status
				objectId
				object{
					id
					name
					description
					note
					images
					images_720
					images_1024
					videos
					location
					place
					address
					people
					peopleInfo
					bed
					bedInfo
					square
					booking
					rules
					heat
					comforts
					tags
					premium
					publish
					companyId
				}
			}
			object{
				id
				name
				description
				note
				images
				images_720
				images_1024
				videos
				location
				place
				address
				people
				peopleInfo
				bed
				bedInfo
				square
				booking
				rules
				heat
				comforts
				tags
				premium
				publish
				companyId
				schedules{
					id
					name
					price
					discount
					reserve
					cancel
					week
					closes
					days
					startAt
					endAt
					fullday
					status
					objectId
				}
				company{
					id
					phone
					contact
					payment
					email
					name
					legal
					splitId
					comment
				}
			}
			user{
				id
				phone
				code
				email
				name
				points
			}
		}
	}
`
export const FIND_MANY_REQUEST = gql`
	query(
		$where: RequestWhereInput
		$orderBy: [RequestOrderByWithRelationInput]
		$cursor: RequestWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [RequestScalarFieldEnum]
	){
		findManyRequest(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		){
			id
			createdAt
			reservedAt
			status
			rating
			ratingAt
			name
			phone
			email
			review
			comment
			people
			paymentId
			scheduleId
			price
			discount
			reserve
			cancel
			objectId
			userId
			pushingNotification
			statusChangeHistory
			schedule{
				id
				name
				price
				discount
				reserve
				cancel
				week
				closes
				days
				startAt
				endAt
				fullday
				status
				objectId
				object{
					id
					name
					description
					note
					images
					images_720
					images_1024
					videos
					location
					place
					address
					people
					peopleInfo
					bed
					bedInfo
					square
					booking
					rules
					heat
					comforts
					tags
					premium
					publish
					companyId
				}
			}
			object{
				id
				name
				description
				note
				images
				images_720
				images_1024
				videos
				location
				place
				address
				people
				peopleInfo
				bed
				bedInfo
				square
				booking
				rules
				heat
				comforts
				tags
				premium
				publish
				companyId
				schedules{
					id
					name
					price
					discount
					reserve
					cancel
					week
					closes
					days
					startAt
					endAt
					fullday
					status
					objectId
				}
				company{
					id
					phone
					contact
					payment
					email
					name
					legal
					splitId
					comment
				}
			}
			user{
				id
				phone
				code
				email
				name
				points
			}
		}
	}
`
export const FIND_MANY_REQUEST_FOR_ADMIN = gql`
	query(
		$where: RequestWhereInput
		$orderBy: [RequestOrderByWithRelationInput]
		$cursor: RequestWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [RequestScalarFieldEnum]
	){
		findManyRequest(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		){
			id
			createdAt
			reservedAt
			status
			rating
			ratingAt
			name
			scheduleId
			price
			schedule{
				id
				name
				price
				week
				closes
				days
				startAt
				endAt
				status
			}
			object{
				id
				name
				images
				images_720
				publish
			}
		}
	}
`
export const FIND_MANY_REQUEST_FOR_RESERVATION = gql`
	query(
		$where: RequestWhereInput
		$orderBy: [RequestOrderByWithRelationInput]
		$cursor: RequestWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [RequestScalarFieldEnum]
	){
		findManyRequest(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		){
			id
			createdAt
			reservedAt
			status
			rating
			ratingAt
			name
			scheduleId
			price
			schedule{
				id
				name
				price
				week
				closes
				days
				startAt
				endAt
				status
			}
			object{
				id
				name
				images
				images_720
				publish
				schedules{
					id
					price
					status
				}
			}
		}
	}
`
export const FIND_MANY_FOR_RESERVE_REQUEST = gql`
	query(
		$where: RequestWhereInput
		$orderBy: [RequestOrderByWithRelationInput]
		$cursor: RequestWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [RequestScalarFieldEnum]
	){
		findManyRequest(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		){
			id
			createdAt
			reservedAt
			status
			rating
			ratingAt
			review
			comment
			people
			paymentId
			scheduleId
			price
			discount
			reserve
			cancel
			objectId
			userId
			pushingNotification
			statusChangeHistory
			schedule{
				id
				name
				price
				discount
				reserve
				cancel
				week
				closes
				days
				startAt
				endAt
				fullday
				status
				objectId
				object{
					id
					name
					description
					note
					images
					images_720
					images_1024
					videos
					location
					place
					address
					people
					peopleInfo
					bed
					bedInfo
					square
					booking
					rules
					heat
					comforts
					tags
					premium
					publish
					companyId
				}
			}
			object{
				id
				name
				description
				note
				images
				images_720
				images_1024
				videos
				location
				place
				address
				people
				peopleInfo
				bed
				bedInfo
				square
				booking
				rules
				heat
				comforts
				tags
				premium
				publish
				companyId
				schedules{
					id
					name
					price
					discount
					reserve
					cancel
					week
					closes
					days
					startAt
					endAt
					fullday
					status
					objectId
				}
				company{
					id
					phone
					contact
					payment
					email
					name
					legal
					splitId
					comment
				}
			}
		}
	}
`
export const FIND_MANY_REQUEST_COUNT = gql`
	query(
		$where: RequestWhereInput
		$orderBy: [RequestOrderByWithRelationInput]
		$cursor: RequestWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [RequestScalarFieldEnum]
	){
		findManyRequestCount(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		)
	}
`
export const FIND_UNIQUE_COUPON = gql`
	query(
		$where: CouponWhereUniqueInput!
	){
		findUniqueCoupon(
			where: $where
		){
			id
			code
			companyId
			company{
				id
				phone
				contact
				payment
				email
				name
				legal
				splitId
				comment
				objects{
					id
					name
					description
					note
					images
					videos
					location
					place
					address
					people
					peopleInfo
					bed
					bedInfo
					square
					booking
					rules
					heat
					comforts
					tags
					premium
					publish
					companyId
				}
			}
		}
	}
`
export const FIND_FIRST_COUPON = gql`
	query(
		$where: CouponWhereInput
		$orderBy: [CouponOrderByWithRelationInput]
		$cursor: CouponWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [CouponScalarFieldEnum]
	){
		findFirstCoupon(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		){
			id
			code
			companyId
			company{
				id
				phone
				contact
				payment
				email
				name
				legal
				splitId
				comment
				objects{
					id
					name
					description
					note
					images
					videos
					location
					place
					address
					people
					peopleInfo
					bed
					bedInfo
					square
					booking
					rules
					heat
					comforts
					tags
					premium
					publish
					companyId
				}
			}
		}
	}
`
export const FIND_MANY_COUPON = gql`
	query(
		$where: CouponWhereInput
		$orderBy: [CouponOrderByWithRelationInput]
		$cursor: CouponWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [CouponScalarFieldEnum]
	){
		findManyCoupon(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		){
			id
			code
			companyId
			company{
				id
				phone
				contact
				payment
				email
				name
				legal
				splitId
				comment
				objects{
					id
					name
					description
					note
					images
					videos
					location
					place
					address
					people
					peopleInfo
					bed
					bedInfo
					square
					booking
					rules
					heat
					comforts
					tags
					premium
					publish
					companyId
				}
			}
		}
	}
`
export const FIND_MANY_COUPON_COUNT = gql`
	query(
		$where: CouponWhereInput
		$orderBy: [CouponOrderByWithRelationInput]
		$cursor: CouponWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [CouponScalarFieldEnum]
	){
		findManyCouponCount(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		)
	}
`
export const FIND_UNIQUE_SCHEDULE = gql`
	query(
		$where: ScheduleWhereUniqueInput!
	){
		findUniqueSchedule(
			where: $where
		){
			id
			name
			price
			discount
			reserve
			cancel
			week
			closes
			days
			startAt
			endAt
			fullday
			status
			objectId
			object{
				id
				name
				description
				note
				images
				videos
				location
				place
				address
				people
				peopleInfo
				bed
				bedInfo
				square
				booking
				rules
				heat
				comforts
				tags
				premium
				publish
				companyId
				requests{
					id
					reservedAt
					status
					rating
					review
					comment
					people
					paymentId
					scheduleId
					price
					discount
					reserve
					cancel
					objectId
					userId
				}
				company{
					id
					phone
					contact
					payment
					email
					name
					legal
					splitId
					comment
				}
			}
			requests{
				id
				reservedAt
				status
				rating
				review
				comment
				people
				paymentId
				scheduleId
				price
				discount
				reserve
				cancel
				objectId
				userId
				object{
					id
					name
					description
					note
					images
					videos
					location
					place
					address
					people
					peopleInfo
					bed
					bedInfo
					square
					booking
					rules
					heat
					comforts
					tags
					premium
					publish
					companyId
				}
			}
		}
	}
`
export const FIND_FIRST_SCHEDULE = gql`
	query(
		$where: ScheduleWhereInput
		$orderBy: [ScheduleOrderByWithRelationInput]
		$cursor: ScheduleWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [ScheduleScalarFieldEnum]
	){
		findFirstSchedule(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		){
			id
			name
			price
			discount
			reserve
			cancel
			week
			closes
			days
			startAt
			endAt
			fullday
			status
			objectId
			object{
				id
				name
				description
				note
				images
				videos
				location
				place
				address
				people
				peopleInfo
				bed
				bedInfo
				square
				booking
				rules
				heat
				comforts
				tags
				premium
				publish
				companyId
				requests{
					id
					reservedAt
					status
					rating
					review
					comment
					people
					paymentId
					scheduleId
					price
					discount
					reserve
					cancel
					objectId
					userId
				}
				company{
					id
					phone
					contact
					payment
					email
					name
					legal
					splitId
					comment
				}
			}
			requests{
				id
				reservedAt
				status
				rating
				review
				comment
				people
				paymentId
				scheduleId
				price
				discount
				reserve
				cancel
				objectId
				userId
				object{
					id
					name
					description
					note
					images
					videos
					location
					place
					address
					people
					peopleInfo
					bed
					bedInfo
					square
					booking
					rules
					heat
					comforts
					tags
					premium
					publish
					companyId
				}
			}
		}
	}
`
export const FIND_MANY_SCHEDULE = gql`
	query(
		$where: ScheduleWhereInput
		$orderBy: [ScheduleOrderByWithRelationInput]
		$cursor: ScheduleWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [ScheduleScalarFieldEnum]
	){
		findManySchedule(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		){
			id
			name
			price
			discount
			reserve
			cancel
			week
			closes
			days
			startAt
			endAt
			fullday
			status
			objectId
			object{
				id
				name
				description
				note
				images
				videos
				location
				place
				address
				people
				peopleInfo
				bed
				bedInfo
				square
				booking
				rules
				heat
				comforts
				tags
				premium
				publish
				companyId
				requests{
					id
					reservedAt
					status
					rating
					review
					comment
					people
					paymentId
					scheduleId
					price
					discount
					reserve
					cancel
					objectId
					userId
				}
				company{
					id
					phone
					contact
					payment
					email
					name
					legal
					splitId
					comment
				}
			}
			requests{
				id
				reservedAt
				status
				rating
				review
				comment
				people
				paymentId
				scheduleId
				price
				discount
				reserve
				cancel
				objectId
				userId
				object{
					id
					name
					description
					note
					images
					videos
					location
					place
					address
					people
					peopleInfo
					bed
					bedInfo
					square
					booking
					rules
					heat
					comforts
					tags
					premium
					publish
					companyId
				}
			}
		}
	}
`
export const FIND_MANY_SCHEDULE_FOR_ADMIN = gql`
	query(
		$where: ScheduleWhereInput
		$orderBy: [ScheduleOrderByWithRelationInput]
		$cursor: ScheduleWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [ScheduleScalarFieldEnum]
	){
		findManySchedule(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		){
			id
			week
			closes
			days
			status
		}
	}
`
export const FIND_MANY_SCHEDULE_COUNT = gql`
	query(
		$where: ScheduleWhereInput
		$orderBy: [ScheduleOrderByWithRelationInput]
		$cursor: ScheduleWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [ScheduleScalarFieldEnum]
	){
		findManyScheduleCount(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		)
	}
`
export const FIND_UNIQUE_TOUR = gql`
	query(
		$where: TourWhereUniqueInput!
	){
		findUniqueTour(
			where: $where
		){
			id
			name
			description
			phone
			images
			images_720
			images_1024
			organization
			price
			priceInfo
			lasting
			location
			schedule
			dates
			people
			includes
			takes
			categories
			transportations
			publish
		}
	}
`
export const FIND_FIRST_TOUR = gql`
	query(
		$where: TourWhereInput
		$orderBy: [TourOrderByWithRelationInput]
		$cursor: TourWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [TourScalarFieldEnum]
	){
		findFirstTour(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		){
			id
			name
			description
			phone
			images
			images_720
			images_1024
			organization
			price
			priceInfo
			lasting
			location
			schedule
			dates
			people
			includes
			takes
			categories
			transportations
			publish
		}
	}
`
export const FIND_MANY_TOUR = gql`
	query(
		$where: TourWhereInput
		$orderBy: [TourOrderByWithRelationInput]
		$cursor: TourWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [TourScalarFieldEnum]
	){
		findManyTour(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		){
			id
			name
			description
			phone
			images
			images_720
			images_1024
			organization
			price
			priceInfo
			lasting
			location
			schedule
			dates
			people
			includes
			takes
			categories
			transportations
			publish
		}
	}
`
export const FIND_MANY_TOUR_COUNT = gql`
	query(
		$where: TourWhereInput
		$orderBy: [TourOrderByWithRelationInput]
		$cursor: TourWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [TourScalarFieldEnum]
	){
		findManyTourCount(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		)
	}
`
export const FIND_UNIQUE_POST = gql`
	query(
		$where: PostWhereUniqueInput!
	){
		findUniquePost(
			where: $where
		){
			id
			postId
			user
			text
			image
			avatar
			date
		}
	}
`
export const FIND_FIRST_POST = gql`
	query(
		$where: PostWhereInput
		$orderBy: [PostOrderByWithRelationInput]
		$cursor: PostWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [PostScalarFieldEnum]
	){
		findFirstPost(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		){
			id
			postId
			user
			text
			image
			avatar
			date
		}
	}
`
export const FIND_MANY_POST = gql`
	query(
		$where: PostWhereInput
		$orderBy: [PostOrderByWithRelationInput]
		$cursor: PostWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [PostScalarFieldEnum]
	){
		findManyPost(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		){
			id
			postId
			user
			text
			image
			avatar
			date
		}
	}
`
export const FIND_MANY_POST_COUNT = gql`
	query(
		$where: PostWhereInput
		$orderBy: [PostOrderByWithRelationInput]
		$cursor: PostWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [PostScalarFieldEnum]
	){
		findManyPostCount(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		)
	}
`
export const FIND_UNIQUE_PROMOTION = gql`
	query(
		$where: PromotionWhereUniqueInput!
	){
		findUniquePromotion(
			where: $where
		){
			id
			name
			weight
			objects
			tours
			posts
		}
	}
`
export const FIND_FIRST_PROMOTION = gql`
	query(
		$where: PromotionWhereInput
		$orderBy: [PromotionOrderByWithRelationInput]
		$cursor: PromotionWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [PromotionScalarFieldEnum]
	){
		findFirstPromotion(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		){
			id
			name
			weight
			objects
			tours
			posts
		}
	}
`
export const FIND_MANY_PROMOTION = gql`
	query(
		$where: PromotionWhereInput
		$orderBy: [PromotionOrderByWithRelationInput]
		$cursor: PromotionWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [PromotionScalarFieldEnum]
	){
		findManyPromotion(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		){
			id
			name
			weight
			objects
			tours
			posts
			status
		}
	}
`
export const FIND_MANY_PROMOTION_COUNT = gql`
	query(
		$where: PromotionWhereInput
		$orderBy: [PromotionOrderByWithRelationInput]
		$cursor: PromotionWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [PromotionScalarFieldEnum]
	){
		findManyPromotionCount(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		)
	}
`
export const FIND_UNIQUE_ICON = gql`
	query(
		$where: IconWhereUniqueInput!
	){
		findUniqueIcon(
			where: $where
		){
			id
			name
			category
			image
		}
	}
`
export const FIND_FIRST_ICON = gql`
	query(
		$where: IconWhereInput
		$orderBy: [IconOrderByWithRelationInput]
		$cursor: IconWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [IconScalarFieldEnum]
	){
		findFirstIcon(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		){
			id
			name
			category
			image
		}
	}
`
export const FIND_MANY_ICON = gql`
	query(
		$where: IconWhereInput
		$orderBy: [IconOrderByWithRelationInput]
		$cursor: IconWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [IconScalarFieldEnum]
	){
		findManyIcon(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		){
			id
			name
			category
			image
		}
	}
`
export const FIND_MANY_ICON_COUNT = gql`
	query(
		$where: IconWhereInput
		$orderBy: [IconOrderByWithRelationInput]
		$cursor: IconWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [IconScalarFieldEnum]
	){
		findManyIconCount(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		)
	}
`
export const FIND_UNIQUE_ADMIN_TG_BOT = gql`
	query(
		$where: AdminTgBotWhereUniqueInput!
	){
		findUniqueAdminTgBot(
			where: $where
		){
			id
			phone
			code
			step
			chatId
			auth
			companyId
			company{
				id
				phone
				contact
				payment
				paymentCardNumber
				paymentType
				email
				name
				legal
				splitId
				comment
				coupons{
					id
					code
					companyId
				}
				objects{
					id
					name
					description
					note
					images
					images_720
					images_1024
					videos
					location
					place
					address
					people
					peopleInfo
					bed
					bedInfo
					square
					booking
					rules
					heat
					comforts
					tags
					premium
					publish
					companyId
				}
			}
		}
	}
`
export const FIND_FIRST_ADMIN_TG_BOT = gql`
	query(
		$where: AdminTgBotWhereInput
		$orderBy: [AdminTgBotOrderByWithRelationInput]
		$cursor: AdminTgBotWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [AdminTgBotScalarFieldEnum]
	){
		findFirstAdminTgBot(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		){
			id
			phone
			code
			step
			chatId
			auth
			companyId
			company{
				id
				phone
				contact
				payment
				paymentCardNumber
				paymentType
				email
				name
				legal
				splitId
				comment
				coupons{
					id
					code
					companyId
				}
				objects{
					id
					name
					description
					note
					images
					images_720
					images_1024
					videos
					location
					place
					address
					people
					peopleInfo
					bed
					bedInfo
					square
					booking
					rules
					heat
					comforts
					tags
					premium
					publish
					companyId
				}
			}
		}
	}
`
export const FIND_MANY_ADMIN_TG_BOT = gql`
	query(
		$where: AdminTgBotWhereInput
		$orderBy: [AdminTgBotOrderByWithRelationInput]
		$cursor: AdminTgBotWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [AdminTgBotScalarFieldEnum]
	){
		findManyAdminTgBot(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		){
			id
			phone
			code
			step
			chatId
			auth
			companyId
			company{
				id
				phone
				contact
				payment
				paymentCardNumber
				paymentType
				email
				name
				legal
				splitId
				comment
				coupons{
					id
					code
					companyId
				}
				objects{
					id
					name
					description
					note
					images
					images_720
					images_1024
					videos
					location
					place
					address
					people
					peopleInfo
					bed
					bedInfo
					square
					booking
					rules
					heat
					comforts
					tags
					premium
					publish
					companyId
				}
			}
		}
	}
`
export const FIND_MANY_ADMIN_TG_BOT_COUNT = gql`
	query(
		$where: AdminTgBotWhereInput
		$orderBy: [AdminTgBotOrderByWithRelationInput]
		$cursor: AdminTgBotWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [AdminTgBotScalarFieldEnum]
	){
		findManyAdminTgBotCount(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		)
	}
`
export const FIND_UNIQUE_COMPANY_BILLING = gql`
	query(
		$where: CompanyBillingWhereUniqueInput!
	){
		findUniqueCompanyBilling(
			where: $where
		){
			id
			fullName
			shortName
			inn
			kpp
			ogrn
			zip
			city
			street
			email
			lastName
			firstName
			middleName
			phone
			account
			bankName
			bik
			correspAcc
			companyId
			company{
				id
				phone
				contact
				payment
				paymentCardNumber
				paymentType
				email
				name
				legal
				splitId
				comment
				coupons{
					id
					code
					companyId
				}
				objects{
					id
					name
					description
					note
					images
					images_720
					images_1024
					videos
					location
					place
					address
					people
					peopleInfo
					bed
					bedInfo
					square
					booking
					rules
					heat
					comforts
					tags
					premium
					publish
					companyId
				}
				AdminTgBot{
					id
					phone
					code
					step
					chatId
					auth
					companyId
				}
			}
		}
	}
`
export const FIND_FIRST_COMPANY_BILLING = gql`
	query(
		$where: CompanyBillingWhereInput
		$orderBy: [CompanyBillingOrderByWithRelationInput]
		$cursor: CompanyBillingWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [CompanyBillingScalarFieldEnum]
	){
		findFirstCompanyBilling(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		){
			id
			fullName
			shortName
			inn
			kpp
			ogrn
			zip
			city
			street
			email
			lastName
			firstName
			middleName
			phone
			account
			bankName
			bik
			correspAcc
			companyId
			company{
				id
				phone
				contact
				payment
				paymentCardNumber
				paymentType
				email
				name
				legal
				splitId
				comment
				coupons{
					id
					code
					companyId
				}
				objects{
					id
					name
					description
					note
					images
					images_720
					images_1024
					videos
					location
					place
					address
					people
					peopleInfo
					bed
					bedInfo
					square
					booking
					rules
					heat
					comforts
					tags
					premium
					publish
					companyId
				}
				AdminTgBot{
					id
					phone
					code
					step
					chatId
					auth
					companyId
				}
			}
		}
	}
`
export const FIND_MANY_COMPANY_BILLING = gql`
	query(
		$where: CompanyBillingWhereInput
		$orderBy: [CompanyBillingOrderByWithRelationInput]
		$cursor: CompanyBillingWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [CompanyBillingScalarFieldEnum]
	){
		findManyCompanyBilling(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		){
			id
			fullName
			shortName
			inn
			kpp
			ogrn
			zip
			city
			street
			email
			lastName
			firstName
			middleName
			phone
			account
			bankName
			bik
			correspAcc
			companyId
			company{
				id
				phone
				contact
				payment
				paymentCardNumber
				paymentType
				email
				name
				legal
				splitId
				comment
				coupons{
					id
					code
					companyId
				}
				objects{
					id
					name
					description
					note
					images
					images_720
					images_1024
					videos
					location
					place
					address
					people
					peopleInfo
					bed
					bedInfo
					square
					booking
					rules
					heat
					comforts
					tags
					premium
					publish
					companyId
				}
				AdminTgBot{
					id
					phone
					code
					step
					chatId
					auth
					companyId
				}
			}
		}
	}
`
export const FIND_MANY_COMPANY_BILLING_COUNT = gql`
	query(
		$where: CompanyBillingWhereInput
		$orderBy: [CompanyBillingOrderByWithRelationInput]
		$cursor: CompanyBillingWhereUniqueInput
		$take: Int
		$skip: Int
		$distinct: [CompanyBillingScalarFieldEnum]
	){
		findManyCompanyBillingCount(
			where: $where
			orderBy: $orderBy
			cursor: $cursor
			take: $take
			skip: $skip
			distinct: $distinct
		)
	}
`
export const SEND_USER_PHONE = gql`
	mutation(
		$data: UserSendPhoneInput!
	){
		sendUserPhone(
			data: $data
		){
			id
			phone
			code
			email
			name
			points
		}
	}
`
export const SEND_USER_CODE = gql`
	mutation(
		$data: UserSendPhoneAndCodeInput!
	){
		sendUserCode(
			data: $data
		){
			user{
				id
				phone
				code
				email
				name
				points
			}
			token
		}
	}
`
export const CREATE_ONE_USER = gql`
	mutation(
		$data: UserCreateInput!
	){
		createOneUser(
			data: $data
		){
			id
			phone
			code
			email
			name
			points
			requests{
				id
				reservedAt
				status
				rating
				name
				phone
				email
				review
				comment
				people
				paymentId
				scheduleId
				price
				discount
				reserve
				cancel
				objectId
				userId
				schedule{
					id
					name
					price
					discount
					reserve
					cancel
					week
					closes
					days
					startAt
					endAt
					fullday
					status
					objectId
				}
				object{
					id
					name
					description
					note
					images
					videos
					location
					place
					address
					people
					peopleInfo
					bed
					bedInfo
					square
					booking
					rules
					heat
					comforts
					tags
					premium
					publish
					companyId
				}
			}
		}
	}
`
export const UPDATE_ONE_USER = gql`
	mutation(
		$data: UserUpdateInput!
		$where: UserWhereUniqueInput!
	){
		updateOneUser(
			data: $data
			where: $where
		){
			id
			phone
			code
			email
			name
			points
			requests{
				id
				reservedAt
				status
				rating
				name
				phone
				email
				review
				comment
				people
				paymentId
				scheduleId
				price
				discount
				reserve
				cancel
				objectId
				userId
				schedule{
					id
					name
					price
					discount
					reserve
					cancel
					week
					closes
					days
					startAt
					endAt
					fullday
					status
					objectId
				}
				object{
					id
					name
					description
					note
					images
					videos
					location
					place
					address
					people
					peopleInfo
					bed
					bedInfo
					square
					booking
					rules
					heat
					comforts
					tags
					premium
					publish
					companyId
				}
			}
		}
	}
`
export const DELETE_ONE_USER = gql`
	mutation(
		$where: UserWhereUniqueInput!
	){
		deleteOneUser(
			where: $where
		){
			id
			phone
			code
			email
			name
			points
			requests{
				id
				reservedAt
				status
				rating
				name
				phone
				email
				review
				comment
				people
				paymentId
				scheduleId
				price
				discount
				reserve
				cancel
				objectId
				userId
				schedule{
					id
					name
					price
					discount
					reserve
					cancel
					week
					closes
					days
					startAt
					endAt
					fullday
					status
					objectId
				}
				object{
					id
					name
					description
					note
					images
					videos
					location
					place
					address
					people
					peopleInfo
					bed
					bedInfo
					square
					booking
					rules
					heat
					comforts
					tags
					premium
					publish
					companyId
				}
			}
		}
	}
`
export const UPSERT_ONE_USER = gql`
	mutation(
		$where: UserWhereUniqueInput!
		$create: UserCreateInput!
		$update: UserUpdateInput!
	){
		upsertOneUser(
			where: $where
			create: $create
			update: $update
		){
			id
			phone
			code
			email
			name
			points
			requests{
				id
				reservedAt
				status
				rating
				name
				phone
				email
				review
				comment
				people
				paymentId
				scheduleId
				price
				discount
				reserve
				cancel
				objectId
				userId
				schedule{
					id
					name
					price
					discount
					reserve
					cancel
					week
					closes
					days
					startAt
					endAt
					fullday
					status
					objectId
				}
				object{
					id
					name
					description
					note
					images
					videos
					location
					place
					address
					people
					peopleInfo
					bed
					bedInfo
					square
					booking
					rules
					heat
					comforts
					tags
					premium
					publish
					companyId
				}
			}
		}
	}
`
export const DELETE_MANY_USER = gql`
	mutation(
		$where: UserWhereInput
	){
		deleteManyUser(
			where: $where
		){
			count
		}
	}
`
export const UPDATE_MANY_USER = gql`
	mutation(
		$data: UserUpdateManyMutationInput!
		$where: UserWhereInput
	){
		updateManyUser(
			data: $data
			where: $where
		){
			count
		}
	}
`
export const SIGN_IN_ADMIN = gql`
	mutation(
		$data: AdminSignInInput!
	){
		signInAdmin(
			data: $data
		){
			admin{
				id
				login
				password
			}
			token
		}
	}
`
export const CREATE_ONE_ADMIN = gql`
	mutation(
		$data: AdminCreateInput!
	){
		createOneAdmin(
			data: $data
		){
			id
			login
			password
		}
	}
`
export const UPDATE_ONE_ADMIN = gql`
	mutation(
		$data: AdminUpdateInput!
		$where: AdminWhereUniqueInput!
	){
		updateOneAdmin(
			data: $data
			where: $where
		){
			id
			login
			password
		}
	}
`
export const DELETE_ONE_ADMIN = gql`
	mutation(
		$where: AdminWhereUniqueInput!
	){
		deleteOneAdmin(
			where: $where
		){
			id
			login
			password
		}
	}
`
export const UPSERT_ONE_ADMIN = gql`
	mutation(
		$where: AdminWhereUniqueInput!
		$create: AdminCreateInput!
		$update: AdminUpdateInput!
	){
		upsertOneAdmin(
			where: $where
			create: $create
			update: $update
		){
			id
			login
			password
		}
	}
`
export const DELETE_MANY_ADMIN = gql`
	mutation(
		$where: AdminWhereInput
	){
		deleteManyAdmin(
			where: $where
		){
			count
		}
	}
`
export const UPDATE_MANY_ADMIN = gql`
	mutation(
		$data: AdminUpdateManyMutationInput!
		$where: AdminWhereInput
	){
		updateManyAdmin(
			data: $data
			where: $where
		){
			count
		}
	}
`
export const REGISTER_COMPANY_BILLING = gql`
	mutation(
		$where: CompanyWhereUniqueInput!
		$data: RegisterCompanyBillingInput!
	){
		registerCompanyBilling(
			where: $where
			data: $data
		){
			id
			phone
			contact
			payment
			paymentCardNumber
			paymentType
			email
			name
			legal
			splitId
			comment
			coupons{
				id
				code
				companyId
			}
			objects{
				id
				name
				description
				note
				images
				videos
				location
				place
				address
				people
				peopleInfo
				bed
				bedInfo
				square
				booking
				rules
				heat
				comforts
				tags
				premium
				publish
				companyId
				schedules{
					id
					name
					price
					discount
					reserve
					cancel
					week
					closes
					days
					startAt
					endAt
					fullday
					status
					objectId
				}
				requests{
					id
					reservedAt
					status
					rating
					name
					phone
					email
					review
					comment
					people
					paymentId
					scheduleId
					price
					discount
					reserve
					cancel
					objectId
					userId
				}
			}
		}
	}
`
export const PAYMENT_COMPANY = gql`
	mutation(
		$data: PaymentInput!
	){
		paymentCompany(
			data: $data
		)
	}
`
export const CANCEL_PAYMENT = gql`
	mutation(
		$where: RequestWhereUniqueInput!
	){
		cancelPayment(
			where: $where
		)
	}
`
export const CREATE_ONE_COMPANY = gql`
	mutation(
		$data: CompanyCreateInput!
	){
		createOneCompany(
			data: $data
		){
			id
			phone
			contact
			payment
			paymentCardNumber
			paymentType
			email
			name
			legal
			splitId
			comment
			coupons{
				id
				code
				companyId
			}
			objects{
				id
				name
				description
				note
				images
				videos
				location
				place
				address
				people
				peopleInfo
				bed
				bedInfo
				square
				booking
				rules
				heat
				comforts
				tags
				premium
				publish
				companyId
				schedules{
					id
					name
					price
					discount
					reserve
					cancel
					week
					closes
					days
					startAt
					endAt
					fullday
					status
					objectId
				}
				requests{
					id
					reservedAt
					status
					rating
					name
					phone
					email
					review
					comment
					people
					paymentId
					scheduleId
					price
					discount
					reserve
					cancel
					objectId
					userId
				}
			}
		}
	}
`
export const UPDATE_ONE_COMPANY = gql`
	mutation(
		$data: CompanyUpdateInput!
		$where: CompanyWhereUniqueInput!
	){
		updateOneCompany(
			data: $data
			where: $where
		){
			id
			phone
			contact
			payment
			paymentCardNumber
			paymentType
			email
			name
			legal
			splitId
			comment
			coupons{
				id
				code
				companyId
			}
			objects{
				id
				name
				description
				note
				images
				videos
				location
				place
				address
				people
				peopleInfo
				bed
				bedInfo
				square
				booking
				rules
				heat
				comforts
				tags
				premium
				publish
				companyId
				schedules{
					id
					name
					price
					discount
					reserve
					cancel
					week
					closes
					days
					startAt
					endAt
					fullday
					status
					objectId
				}
				requests{
					id
					reservedAt
					status
					rating
					name
					phone
					email
					review
					comment
					people
					paymentId
					scheduleId
					price
					discount
					reserve
					cancel
					objectId
					userId
				}
			}
		}
	}
`
export const DELETE_ONE_COMPANY = gql`
	mutation(
		$where: CompanyWhereUniqueInput!
	){
		deleteOneCompany(
			where: $where
		){
			id
			phone
			contact
			payment
			paymentCardNumber
			paymentType
			email
			name
			legal
			splitId
			comment
			coupons{
				id
				code
				companyId
			}
			objects{
				id
				name
				description
				note
				images
				videos
				location
				place
				address
				people
				peopleInfo
				bed
				bedInfo
				square
				booking
				rules
				heat
				comforts
				tags
				premium
				publish
				companyId
				schedules{
					id
					name
					price
					discount
					reserve
					cancel
					week
					closes
					days
					startAt
					endAt
					fullday
					status
					objectId
				}
				requests{
					id
					reservedAt
					status
					rating
					name
					phone
					email
					review
					comment
					people
					paymentId
					scheduleId
					price
					discount
					reserve
					cancel
					objectId
					userId
				}
			}
		}
	}
`
export const UPSERT_ONE_COMPANY = gql`
	mutation(
		$where: CompanyWhereUniqueInput!
		$create: CompanyCreateInput!
		$update: CompanyUpdateInput!
	){
		upsertOneCompany(
			where: $where
			create: $create
			update: $update
		){
			id
			phone
			contact
			payment
			paymentCardNumber
			paymentType
			email
			name
			legal
			splitId
			comment
			coupons{
				id
				code
				companyId
			}
			objects{
				id
				name
				description
				note
				images
				videos
				location
				place
				address
				people
				peopleInfo
				bed
				bedInfo
				square
				booking
				rules
				heat
				comforts
				tags
				premium
				publish
				companyId
				schedules{
					id
					name
					price
					discount
					reserve
					cancel
					week
					closes
					days
					startAt
					endAt
					fullday
					status
					objectId
				}
				requests{
					id
					reservedAt
					status
					rating
					name
					phone
					email
					review
					comment
					people
					paymentId
					scheduleId
					price
					discount
					reserve
					cancel
					objectId
					userId
				}
			}
		}
	}
`
export const DELETE_MANY_COMPANY = gql`
	mutation(
		$where: CompanyWhereInput
	){
		deleteManyCompany(
			where: $where
		){
			count
		}
	}
`
export const UPDATE_MANY_COMPANY = gql`
	mutation(
		$data: CompanyUpdateManyMutationInput!
		$where: CompanyWhereInput
	){
		updateManyCompany(
			data: $data
			where: $where
		){
			count
		}
	}
`
export const CREATE_ONE_OBJECT = gql`
	mutation(
		$data: ObjectCreateInput!
	){
		createOneObject(
			data: $data
		){
			id
			name
			description
			note
			images
			videos
			location
			place
			address
			people
			peopleInfo
			bed
			bedInfo
			square
			booking
			rules
			heat
			comforts
			tags
			premium
			publish
			companyId
			favorite @client
			schedules{
				id
				name
				price
				discount
				reserve
				cancel
				week
				closes
				days
				startAt
				endAt
				fullday
				status
				objectId
				requests{
					id
					reservedAt
					status
					rating
					name
					phone
					email
					review
					comment
					people
					paymentId
					scheduleId
					price
					discount
					reserve
					cancel
					objectId
					userId
				}
			}
			requests{
				id
				reservedAt
				status
				rating
				name
				phone
				email
				review
				comment
				people
				paymentId
				scheduleId
				price
				discount
				reserve
				cancel
				objectId
				userId
				schedule{
					id
					name
					price
					discount
					reserve
					cancel
					week
					closes
					days
					startAt
					endAt
					fullday
					status
					objectId
				}
				user{
					id
					phone
					code
					email
					name
					points
				}
			}
			company{
				id
				phone
				contact
				payment
				email
				name
				legal
				splitId
				comment
				coupons{
					id
					code
					companyId
				}
			}
		}
	}
`
export const UPDATE_ONE_OBJECT = gql`
	mutation(
		$data: ObjectUpdateInput!
		$where: ObjectWhereUniqueInput!
	){
		updateOneObject(
			data: $data
			where: $where
		){
			id
			name
			description
			note
			images
			images_720
			images_1024
			videos
			location
			place
			address
			people
			peopleInfo
			bed
			bedInfo
			square
			booking
			rules
			heat
			comforts
			tags
			premium
			rating
			publish
			companyId
			favorite @client
			schedules{
				id
				name
				price
				discount
				reserve
				cancel
				week
				closes
				days
				startAt
				endAt
				fullday
				status
				objectId
				requests{
					id
					reservedAt
					status
					rating
					name
					phone
					email
					review
					comment
					people
					paymentId
					scheduleId
					price
					discount
					reserve
					cancel
					objectId
					userId
				}
			}
			requests{
				id
				reservedAt
				status
				rating
				name
				phone
				email
				review
				comment
				people
				paymentId
				scheduleId
				price
				discount
				reserve
				cancel
				objectId
				userId
				schedule{
					id
					name
					price
					discount
					reserve
					cancel
					week
					closes
					days
					startAt
					endAt
					fullday
					status
					objectId
				}
				user{
					id
					phone
					code
					email
					name
					points
				}
			}
			company{
				id
				phone
				contact
				payment
				email
				name
				legal
				splitId
				comment
				coupons{
					id
					code
					companyId
				}
			}
		}
	}
`
export const DELETE_ONE_OBJECT = gql`
	mutation(
		$where: ObjectWhereUniqueInput!
	){
		deleteOneObject(
			where: $where
		){
			id
			name
			description
			note
			images
			videos
			location
			place
			address
			people
			peopleInfo
			bed
			bedInfo
			square
			booking
			rules
			heat
			comforts
			tags
			premium
			publish
			companyId
			favorite @client
			schedules{
				id
				name
				price
				discount
				reserve
				cancel
				week
				closes
				days
				startAt
				endAt
				fullday
				status
				objectId
				requests{
					id
					reservedAt
					status
					rating
					name
					phone
					email
					review
					comment
					people
					paymentId
					scheduleId
					price
					discount
					reserve
					cancel
					objectId
					userId
				}
			}
			requests{
				id
				reservedAt
				status
				rating
				name
				phone
				email
				review
				comment
				people
				paymentId
				scheduleId
				price
				discount
				reserve
				cancel
				objectId
				userId
				schedule{
					id
					name
					price
					discount
					reserve
					cancel
					week
					closes
					days
					startAt
					endAt
					fullday
					status
					objectId
				}
				user{
					id
					phone
					code
					email
					name
					points
				}
			}
			company{
				id
				phone
				contact
				payment
				email
				name
				legal
				splitId
				comment
				coupons{
					id
					code
					companyId
				}
			}
		}
	}
`
export const UPSERT_ONE_OBJECT = gql`
	mutation(
		$where: ObjectWhereUniqueInput!
		$create: ObjectCreateInput!
		$update: ObjectUpdateInput!
	){
		upsertOneObject(
			where: $where
			create: $create
			update: $update
		){
			id
			name
			description
			note
			images
			images_720
			images_1024
			videos
			location
			place
			address
			people
			peopleInfo
			bed
			bedInfo
			square
			booking
			rules
			heat
			comforts
			tags
			premium
			publish
			companyId
			favorite @client
			schedules{
				id
				name
				price
				discount
				reserve
				cancel
				week
				closes
				days
				startAt
				endAt
				fullday
				status
				objectId
				requests{
					id
					reservedAt
					status
					rating
					name
					phone
					email
					review
					comment
					people
					paymentId
					scheduleId
					price
					discount
					reserve
					cancel
					objectId
					userId
				}
			}
			requests{
				id
				reservedAt
				status
				rating
				name
				phone
				email
				review
				comment
				people
				paymentId
				scheduleId
				price
				discount
				reserve
				cancel
				objectId
				userId
				schedule{
					id
					name
					price
					discount
					reserve
					cancel
					week
					closes
					days
					startAt
					endAt
					fullday
					status
					objectId
				}
				user{
					id
					phone
					code
					email
					name
					points
				}
			}
			company{
				id
				phone
				contact
				payment
				email
				name
				legal
				splitId
				comment
				coupons{
					id
					code
					companyId
				}
			}
		}
	}
`
export const DELETE_MANY_OBJECT = gql`
	mutation(
		$where: ObjectWhereInput
	){
		deleteManyObject(
			where: $where
		){
			count
		}
	}
`
export const UPDATE_MANY_OBJECT = gql`
	mutation(
		$data: ObjectUpdateManyMutationInput!
		$where: ObjectWhereInput
	){
		updateManyObject(
			data: $data
			where: $where
		){
			count
		}
	}
`
export const CREATE_ONE_REQUEST = gql`
	mutation(
		$data: RequestCreateInput!
	){
		createOneRequest(
			data: $data
		){
			id
			reservedAt
			status
			rating
			ratingAt
			name
			phone
			email
			review
			comment
			people
			paymentId
			scheduleId
			price
			discount
			reserve
			cancel
			objectId
			userId
			pushingNotification
			statusChangeHistory
			schedule{
				id
				name
				price
				discount
				reserve
				cancel
				week
				closes
				days
				startAt
				endAt
				fullday
				status
				objectId
				object{
					id
					name
					description
					note
					images
					images_720
					images_1024
					videos
					location
					place
					address
					people
					peopleInfo
					bed
					bedInfo
					square
					booking
					rules
					heat
					comforts
					tags
					premium
					publish
					companyId
				}
			}
			object{
				id
				name
				description
				note
				images
				images_720
				images_1024
				videos
				location
				place
				address
				people
				peopleInfo
				bed
				bedInfo
				square
				booking
				rules
				heat
				comforts
				tags
				premium
				publish
				companyId
				schedules{
					id
					name
					price
					discount
					reserve
					cancel
					week
					closes
					days
					startAt
					endAt
					fullday
					status
					objectId
				}
				company{
					id
					phone
					contact
					payment
					paymentCardNumber
					paymentType
					email
					name
					legal
					splitId
					comment
					CompanyBilling{
						id
						fullName
						shortName
						inn
						kpp
						ogrn
						zip
						city
						street
						email
						lastName
						firstName
						middleName
						phone
						account
						bankName
						bik
						correspAcc
						companyId
					}
				}
			}
			user{
				id
				phone
				code
				email
				name
				points
			}
		}
	}
`
export const UPDATE_ONE_REQUEST = gql`
	mutation(
		$data: RequestUpdateInput!
		$where: RequestWhereUniqueInput!
	){
		updateOneRequest(
			data: $data
			where: $where
		){
			id
			reservedAt
			status
			rating
			ratingAt
			name
			phone
			email
			review
			comment
			people
			paymentId
			scheduleId
			price
			discount
			reserve
			cancel
			objectId
			userId
			pushingNotification
			statusChangeHistory
			schedule{
				id
				name
				price
				discount
				reserve
				cancel
				week
				closes
				days
				startAt
				endAt
				fullday
				status
				objectId
				object{
					id
					name
					description
					note
					images
					images_720
					images_1024
					videos
					location
					place
					address
					people
					peopleInfo
					bed
					bedInfo
					square
					booking
					rules
					heat
					comforts
					tags
					premium
					publish
					companyId
				}
			}
			object{
				id
				name
				description
				note
				images
				images_720
				images_1024
				videos
				location
				place
				address
				people
				peopleInfo
				bed
				bedInfo
				square
				booking
				rules
				heat
				comforts
				tags
				premium
				publish
				companyId
				schedules{
					id
					name
					price
					discount
					reserve
					cancel
					week
					closes
					days
					startAt
					endAt
					fullday
					status
					objectId
				}
				company{
					id
					phone
					contact
					payment
					email
					name
					legal
					splitId
					comment
				}
			}
			user{
				id
				phone
				code
				email
				name
				points
			}
		}
	}
`
export const DELETE_ONE_REQUEST = gql`
	mutation(
		$where: RequestWhereUniqueInput!
	){
		deleteOneRequest(
			where: $where
		){
			id
			reservedAt
			status
			rating
			ratingAt
			name
			phone
			email
			review
			comment
			people
			paymentId
			scheduleId
			price
			discount
			reserve
			cancel
			objectId
			userId
			pushingNotification
			statusChangeHistory
			schedule{
				id
				name
				price
				discount
				reserve
				cancel
				week
				closes
				days
				startAt
				endAt
				fullday
				status
				objectId
				object{
					id
					name
					description
					note
					images
					videos
					location
					place
					address
					people
					peopleInfo
					bed
					bedInfo
					square
					booking
					rules
					heat
					comforts
					tags
					premium
					publish
					companyId
				}
			}
			object{
				id
				name
				description
				note
				images
				videos
				location
				place
				address
				people
				peopleInfo
				bed
				bedInfo
				square
				booking
				rules
				heat
				comforts
				tags
				premium
				publish
				companyId
				schedules{
					id
					name
					price
					discount
					reserve
					cancel
					week
					closes
					days
					startAt
					endAt
					fullday
					status
					objectId
				}
				company{
					id
					phone
					contact
					payment
					email
					name
					legal
					splitId
					comment
				}
			}
			user{
				id
				phone
				code
				email
				name
				points
			}
		}
	}
`
export const UPSERT_ONE_REQUEST = gql`
	mutation(
		$where: RequestWhereUniqueInput!
		$create: RequestCreateInput!
		$update: RequestUpdateInput!
	){
		upsertOneRequest(
			where: $where
			create: $create
			update: $update
		){
			id
			reservedAt
			status
			rating
			name
			phone
			email
			review
			comment
			people
			paymentId
			scheduleId
			price
			discount
			reserve
			cancel
			objectId
			userId
			pushingNotification
			statusChangeHistory
			schedule{
				id
				name
				price
				discount
				reserve
				cancel
				week
				closes
				days
				startAt
				endAt
				fullday
				status
				objectId
				object{
					id
					name
					description
					note
					images
					images_720
					images_1024
					videos
					location
					place
					address
					people
					peopleInfo
					bed
					bedInfo
					square
					booking
					rules
					heat
					comforts
					tags
					premium
					publish
					companyId
				}
			}
			object{
				id
				name
				description
				note
				images
				images_720
				images_1024
				videos
				location
				place
				address
				people
				peopleInfo
				bed
				bedInfo
				square
				booking
				rules
				heat
				comforts
				tags
				premium
				publish
				companyId
				schedules{
					id
					name
					price
					discount
					reserve
					cancel
					week
					closes
					days
					startAt
					endAt
					fullday
					status
					objectId
				}
				company{
					id
					phone
					contact
					payment
					email
					name
					legal
					splitId
					comment
				}
			}
			user{
				id
				phone
				code
				email
				name
				points
			}
		}
	}
`
export const DELETE_MANY_REQUEST = gql`
	mutation(
		$where: RequestWhereInput
	){
		deleteManyRequest(
			where: $where
		){
			count
		}
	}
`
export const UPDATE_MANY_REQUEST = gql`
	mutation(
		$data: RequestUpdateManyMutationInput!
		$where: RequestWhereInput
	){
		updateManyRequest(
			data: $data
			where: $where
		){
			count
		}
	}
`
export const CREATE_ONE_COUPON = gql`
	mutation(
		$data: CouponCreateInput!
	){
		createOneCoupon(
			data: $data
		){
			id
			code
			companyId
			company{
				id
				phone
				contact
				payment
				email
				name
				legal
				splitId
				comment
				objects{
					id
					name
					description
					note
					images
					videos
					location
					place
					address
					people
					peopleInfo
					bed
					bedInfo
					square
					booking
					rules
					heat
					comforts
					tags
					premium
					publish
					companyId
				}
			}
		}
	}
`
export const UPDATE_ONE_COUPON = gql`
	mutation(
		$data: CouponUpdateInput!
		$where: CouponWhereUniqueInput!
	){
		updateOneCoupon(
			data: $data
			where: $where
		){
			id
			code
			companyId
			company{
				id
				phone
				contact
				payment
				email
				name
				legal
				splitId
				comment
				objects{
					id
					name
					description
					note
					images
					videos
					location
					place
					address
					people
					peopleInfo
					bed
					bedInfo
					square
					booking
					rules
					heat
					comforts
					tags
					premium
					publish
					companyId
				}
			}
		}
	}
`
export const DELETE_ONE_COUPON = gql`
	mutation(
		$where: CouponWhereUniqueInput!
	){
		deleteOneCoupon(
			where: $where
		){
			id
			code
			companyId
			company{
				id
				phone
				contact
				payment
				email
				name
				legal
				splitId
				comment
				objects{
					id
					name
					description
					note
					images
					videos
					location
					place
					address
					people
					peopleInfo
					bed
					bedInfo
					square
					booking
					rules
					heat
					comforts
					tags
					premium
					publish
					companyId
				}
			}
		}
	}
`
export const UPSERT_ONE_COUPON = gql`
	mutation(
		$where: CouponWhereUniqueInput!
		$create: CouponCreateInput!
		$update: CouponUpdateInput!
	){
		upsertOneCoupon(
			where: $where
			create: $create
			update: $update
		){
			id
			code
			companyId
			company{
				id
				phone
				contact
				payment
				email
				name
				legal
				splitId
				comment
				objects{
					id
					name
					description
					note
					images
					videos
					location
					place
					address
					people
					peopleInfo
					bed
					bedInfo
					square
					booking
					rules
					heat
					comforts
					tags
					premium
					publish
					companyId
				}
			}
		}
	}
`
export const DELETE_MANY_COUPON = gql`
	mutation(
		$where: CouponWhereInput
	){
		deleteManyCoupon(
			where: $where
		){
			count
		}
	}
`
export const UPDATE_MANY_COUPON = gql`
	mutation(
		$data: CouponUpdateManyMutationInput!
		$where: CouponWhereInput
	){
		updateManyCoupon(
			data: $data
			where: $where
		){
			count
		}
	}
`
export const CREATE_ONE_SCHEDULE = gql`
	mutation(
		$data: ScheduleCreateInput!
	){
		createOneSchedule(
			data: $data
		){
			id
			name
			price
			discount
			reserve
			cancel
			week
			closes
			days
			startAt
			endAt
			fullday
			status
			objectId
			object{
				id
				name
				description
				note
				images
				videos
				location
				place
				address
				people
				peopleInfo
				bed
				bedInfo
				square
				booking
				rules
				heat
				comforts
				tags
				premium
				publish
				companyId
				requests{
					id
					reservedAt
					status
					rating
					name
					phone
					email
					review
					comment
					people
					paymentId
					scheduleId
					price
					discount
					reserve
					cancel
					objectId
					userId
				}
				company{
					id
					phone
					contact
					payment
					email
					name
					legal
					splitId
					comment
				}
			}
			requests{
				id
				reservedAt
				status
				rating
				name
				phone
				email
				review
				comment
				people
				paymentId
				scheduleId
				price
				discount
				reserve
				cancel
				objectId
				userId
				object{
					id
					name
					description
					note
					images
					videos
					location
					place
					address
					people
					peopleInfo
					bed
					bedInfo
					square
					booking
					rules
					heat
					comforts
					tags
					premium
					publish
					companyId
				}
				user{
					id
					phone
					code
					email
					name
					points
				}
			}
		}
	}
`
export const UPDATE_ONE_SCHEDULE = gql`
	mutation(
		$data: ScheduleUpdateInput!
		$where: ScheduleWhereUniqueInput!
	){
		updateOneSchedule(
			data: $data
			where: $where
		){
			id
			name
			price
			discount
			reserve
			cancel
			week
			closes
			days
			startAt
			endAt
			fullday
			status
			objectId
			object{
				id
				name
				description
				note
				images
				videos
				location
				place
				address
				people
				peopleInfo
				bed
				bedInfo
				square
				booking
				rules
				heat
				comforts
				tags
				premium
				publish
				companyId
				requests{
					id
					reservedAt
					status
					rating
					name
					phone
					email
					review
					comment
					people
					paymentId
					scheduleId
					price
					discount
					reserve
					cancel
					objectId
					userId
				}
				company{
					id
					phone
					contact
					payment
					email
					name
					legal
					splitId
					comment
				}
			}
			requests{
				id
				reservedAt
				status
				rating
				name
				phone
				email
				review
				comment
				people
				paymentId
				scheduleId
				price
				discount
				reserve
				cancel
				objectId
				userId
				object{
					id
					name
					description
					note
					images
					videos
					location
					place
					address
					people
					peopleInfo
					bed
					bedInfo
					square
					booking
					rules
					heat
					comforts
					tags
					premium
					publish
					companyId
				}
				user{
					id
					phone
					code
					email
					name
					points
				}
			}
		}
	}
`
export const DELETE_ONE_SCHEDULE = gql`
	mutation(
		$where: ScheduleWhereUniqueInput!
	){
		deleteOneSchedule(
			where: $where
		){
			id
			name
			price
			discount
			reserve
			cancel
			week
			closes
			days
			startAt
			endAt
			fullday
			status
			objectId
			object{
				id
				name
				description
				note
				images
				videos
				location
				place
				address
				people
				peopleInfo
				bed
				bedInfo
				square
				booking
				rules
				heat
				comforts
				tags
				premium
				publish
				companyId
				requests{
					id
					reservedAt
					status
					rating
					name
					phone
					email
					review
					comment
					people
					paymentId
					scheduleId
					price
					discount
					reserve
					cancel
					objectId
					userId
				}
				company{
					id
					phone
					contact
					payment
					email
					name
					legal
					splitId
					comment
				}
			}
			requests{
				id
				reservedAt
				status
				rating
				name
				phone
				email
				review
				comment
				people
				paymentId
				scheduleId
				price
				discount
				reserve
				cancel
				objectId
				userId
				object{
					id
					name
					description
					note
					images
					videos
					location
					place
					address
					people
					peopleInfo
					bed
					bedInfo
					square
					booking
					rules
					heat
					comforts
					tags
					premium
					publish
					companyId
				}
				user{
					id
					phone
					code
					email
					name
					points
				}
			}
		}
	}
`
export const UPSERT_ONE_SCHEDULE = gql`
	mutation(
		$where: ScheduleWhereUniqueInput!
		$create: ScheduleCreateInput!
		$update: ScheduleUpdateInput!
	){
		upsertOneSchedule(
			where: $where
			create: $create
			update: $update
		){
			id
			name
			price
			discount
			reserve
			cancel
			week
			closes
			days
			startAt
			endAt
			fullday
			status
			objectId
			object{
				id
				name
				description
				note
				images
				videos
				location
				place
				address
				people
				peopleInfo
				bed
				bedInfo
				square
				booking
				rules
				heat
				comforts
				tags
				premium
				publish
				companyId
				requests{
					id
					reservedAt
					status
					rating
					name
					phone
					email
					review
					comment
					people
					paymentId
					scheduleId
					price
					discount
					reserve
					cancel
					objectId
					userId
				}
				company{
					id
					phone
					contact
					payment
					email
					name
					legal
					splitId
					comment
				}
			}
			requests{
				id
				reservedAt
				status
				rating
				name
				phone
				email
				review
				comment
				people
				paymentId
				scheduleId
				price
				discount
				reserve
				cancel
				objectId
				userId
				object{
					id
					name
					description
					note
					images
					videos
					location
					place
					address
					people
					peopleInfo
					bed
					bedInfo
					square
					booking
					rules
					heat
					comforts
					tags
					premium
					publish
					companyId
				}
				user{
					id
					phone
					code
					email
					name
					points
				}
			}
		}
	}
`
export const DELETE_MANY_SCHEDULE = gql`
	mutation(
		$where: ScheduleWhereInput
	){
		deleteManySchedule(
			where: $where
		){
			count
		}
	}
`
export const UPDATE_MANY_SCHEDULE = gql`
	mutation(
		$data: ScheduleUpdateManyMutationInput!
		$where: ScheduleWhereInput
	){
		updateManySchedule(
			data: $data
			where: $where
		){
			count
		}
	}
`
export const CREATE_ONE_TOUR = gql`
	mutation(
		$data: TourCreateInput!
	){
		createOneTour(
			data: $data
		){
			id
			name
			description
			phone
			images
			organization
			price
			priceInfo
			lasting
			location
			schedule
			dates
			people
			includes
			takes
			categories
			transportations
			publish
		}
	}
`
export const UPDATE_ONE_TOUR = gql`
	mutation(
		$data: TourUpdateInput!
		$where: TourWhereUniqueInput!
	){
		updateOneTour(
			data: $data
			where: $where
		){
			id
			name
			description
			phone
			images
			organization
			price
			priceInfo
			lasting
			location
			schedule
			dates
			people
			includes
			takes
			categories
			transportations
			publish
		}
	}
`
export const DELETE_ONE_TOUR = gql`
	mutation(
		$where: TourWhereUniqueInput!
	){
		deleteOneTour(
			where: $where
		){
			id
			name
			description
			phone
			images
			organization
			price
			priceInfo
			lasting
			location
			schedule
			dates
			people
			includes
			takes
			categories
			transportations
			publish
		}
	}
`
export const UPSERT_ONE_TOUR = gql`
	mutation(
		$where: TourWhereUniqueInput!
		$create: TourCreateInput!
		$update: TourUpdateInput!
	){
		upsertOneTour(
			where: $where
			create: $create
			update: $update
		){
			id
			name
			description
			phone
			images
			organization
			price
			priceInfo
			lasting
			location
			schedule
			dates
			people
			includes
			takes
			categories
			transportations
			publish
		}
	}
`
export const DELETE_MANY_TOUR = gql`
	mutation(
		$where: TourWhereInput
	){
		deleteManyTour(
			where: $where
		){
			count
		}
	}
`
export const UPDATE_MANY_TOUR = gql`
	mutation(
		$data: TourUpdateManyMutationInput!
		$where: TourWhereInput
	){
		updateManyTour(
			data: $data
			where: $where
		){
			count
		}
	}
`
export const CREATE_ONE_POST = gql`
	mutation(
		$data: PostCreateInput!
	){
		createOnePost(
			data: $data
		){
			id
			postId
			user
			text
			image
			avatar
			date
		}
	}
`
export const UPDATE_ONE_POST = gql`
	mutation(
		$data: PostUpdateInput!
		$where: PostWhereUniqueInput!
	){
		updateOnePost(
			data: $data
			where: $where
		){
			id
			postId
			user
			text
			image
			avatar
			date
		}
	}
`
export const DELETE_ONE_POST = gql`
	mutation(
		$where: PostWhereUniqueInput!
	){
		deleteOnePost(
			where: $where
		){
			id
			postId
			user
			text
			image
			avatar
			date
		}
	}
`
export const UPSERT_ONE_POST = gql`
	mutation(
		$where: PostWhereUniqueInput!
		$create: PostCreateInput!
		$update: PostUpdateInput!
	){
		upsertOnePost(
			where: $where
			create: $create
			update: $update
		){
			id
			postId
			user
			text
			image
			avatar
			date
		}
	}
`
export const DELETE_MANY_POST = gql`
	mutation(
		$where: PostWhereInput
	){
		deleteManyPost(
			where: $where
		){
			count
		}
	}
`
export const UPDATE_MANY_POST = gql`
	mutation(
		$data: PostUpdateManyMutationInput!
		$where: PostWhereInput
	){
		updateManyPost(
			data: $data
			where: $where
		){
			count
		}
	}
`
export const CREATE_ONE_PROMOTION = gql`
	mutation(
		$data: PromotionCreateInput!
	){
		createOnePromotion(
			data: $data
		){
			id
			name
			weight
			objects
			tours
			posts
		}
	}
`
export const UPDATE_ONE_PROMOTION = gql`
	mutation(
		$data: PromotionUpdateInput!
		$where: PromotionWhereUniqueInput!
	){
		updateOnePromotion(
			data: $data
			where: $where
		){
			id
			name
			weight
			objects
			tours
			posts
		}
	}
`
export const DELETE_ONE_PROMOTION = gql`
	mutation(
		$where: PromotionWhereUniqueInput!
	){
		deleteOnePromotion(
			where: $where
		){
			id
			name
			weight
			objects
			tours
			posts
		}
	}
`
export const UPSERT_ONE_PROMOTION = gql`
	mutation(
		$where: PromotionWhereUniqueInput!
		$create: PromotionCreateInput!
		$update: PromotionUpdateInput!
	){
		upsertOnePromotion(
			where: $where
			create: $create
			update: $update
		){
			id
			name
			weight
			objects
			tours
			posts
		}
	}
`
export const DELETE_MANY_PROMOTION = gql`
	mutation(
		$where: PromotionWhereInput
	){
		deleteManyPromotion(
			where: $where
		){
			count
		}
	}
`
export const UPDATE_MANY_PROMOTION = gql`
	mutation(
		$data: PromotionUpdateManyMutationInput!
		$where: PromotionWhereInput
	){
		updateManyPromotion(
			data: $data
			where: $where
		){
			count
		}
	}
`
export const CREATE_ONE_ICON = gql`
	mutation(
		$data: IconCreateInput!
	){
		createOneIcon(
			data: $data
		){
			id
			name
			category
			image
		}
	}
`
export const UPDATE_ONE_ICON = gql`
	mutation(
		$data: IconUpdateInput!
		$where: IconWhereUniqueInput!
	){
		updateOneIcon(
			data: $data
			where: $where
		){
			id
			name
			category
			image
		}
	}
`
export const DELETE_ONE_ICON = gql`
	mutation(
		$where: IconWhereUniqueInput!
	){
		deleteOneIcon(
			where: $where
		){
			id
			name
			category
			image
		}
	}
`
export const UPSERT_ONE_ICON = gql`
	mutation(
		$where: IconWhereUniqueInput!
		$create: IconCreateInput!
		$update: IconUpdateInput!
	){
		upsertOneIcon(
			where: $where
			create: $create
			update: $update
		){
			id
			name
			category
			image
		}
	}
`
export const DELETE_MANY_ICON = gql`
	mutation(
		$where: IconWhereInput
	){
		deleteManyIcon(
			where: $where
		){
			count
		}
	}
`
export const UPDATE_MANY_ICON = gql`
	mutation(
		$data: IconUpdateManyMutationInput!
		$where: IconWhereInput
	){
		updateManyIcon(
			data: $data
			where: $where
		){
			count
		}
	}
`
export const CREATE_ONE_ADMIN_TG_BOT = gql`
	mutation(
		$data: AdminTgBotCreateInput!
	){
		createOneAdminTgBot(
			data: $data
		){
			id
			phone
			code
			step
			chatId
			auth
			companyId
			company{
				id
				phone
				contact
				payment
				paymentCardNumber
				paymentType
				email
				name
				legal
				splitId
				comment
				coupons{
					id
					code
					companyId
				}
				objects{
					id
					name
					description
					note
					images
					images_720
					images_1024
					videos
					location
					place
					address
					people
					peopleInfo
					bed
					bedInfo
					square
					booking
					rules
					heat
					comforts
					tags
					premium
					publish
					companyId
				}
			}
		}
	}
`
export const UPDATE_ONE_ADMIN_TG_BOT = gql`
	mutation(
		$data: AdminTgBotUpdateInput!
		$where: AdminTgBotWhereUniqueInput!
	){
		updateOneAdminTgBot(
			data: $data
			where: $where
		){
			id
			phone
			code
			step
			chatId
			auth
			companyId
			company{
				id
				phone
				contact
				payment
				paymentCardNumber
				paymentType
				email
				name
				legal
				splitId
				comment
				coupons{
					id
					code
					companyId
				}
				objects{
					id
					name
					description
					note
					images
					images_720
					images_1024
					videos
					location
					place
					address
					people
					peopleInfo
					bed
					bedInfo
					square
					booking
					rules
					heat
					comforts
					tags
					premium
					publish
					companyId
				}
			}
		}
	}
`
export const DELETE_ONE_ADMIN_TG_BOT = gql`
	mutation(
		$where: AdminTgBotWhereUniqueInput!
	){
		deleteOneAdminTgBot(
			where: $where
		){
			id
			phone
			code
			step
			chatId
			auth
			companyId
			company{
				id
				phone
				contact
				payment
				paymentCardNumber
				paymentType
				email
				name
				legal
				splitId
				comment
				coupons{
					id
					code
					companyId
				}
				objects{
					id
					name
					description
					note
					images
					images_720
					images_1024
					videos
					location
					place
					address
					people
					peopleInfo
					bed
					bedInfo
					square
					booking
					rules
					heat
					comforts
					tags
					premium
					publish
					companyId
				}
			}
		}
	}
`
export const UPSERT_ONE_ADMIN_TG_BOT = gql`
	mutation(
		$where: AdminTgBotWhereUniqueInput!
		$create: AdminTgBotCreateInput!
		$update: AdminTgBotUpdateInput!
	){
		upsertOneAdminTgBot(
			where: $where
			create: $create
			update: $update
		){
			id
			phone
			code
			step
			chatId
			auth
			companyId
			company{
				id
				phone
				contact
				payment
				paymentCardNumber
				paymentType
				email
				name
				legal
				splitId
				comment
				coupons{
					id
					code
					companyId
				}
				objects{
					id
					name
					description
					note
					images
					images_720
					images_1024
					videos
					location
					place
					address
					people
					peopleInfo
					bed
					bedInfo
					square
					booking
					rules
					heat
					comforts
					tags
					premium
					publish
					companyId
				}
			}
		}
	}
`
export const DELETE_MANY_ADMIN_TG_BOT = gql`
	mutation(
		$where: AdminTgBotWhereInput
	){
		deleteManyAdminTgBot(
			where: $where
		){
			count
		}
	}
`
export const UPDATE_MANY_ADMIN_TG_BOT = gql`
	mutation(
		$data: AdminTgBotUpdateManyMutationInput!
		$where: AdminTgBotWhereInput
	){
		updateManyAdminTgBot(
			data: $data
			where: $where
		){
			count
		}
	}
`
export const CREATE_ONE_COMPANY_BILLING = gql`
	mutation(
		$data: CompanyBillingCreateInput!
	){
		createOneCompanyBilling(
			data: $data
		){
			id
			fullName
			shortName
			inn
			kpp
			ogrn
			zip
			city
			street
			email
			lastName
			firstName
			middleName
			phone
			account
			bankName
			bik
			correspAcc
			companyId
			company{
				id
				phone
				contact
				payment
				paymentCardNumber
				paymentType
				email
				name
				legal
				splitId
				comment
				coupons{
					id
					code
					companyId
				}
				objects{
					id
					name
					description
					note
					images
					images_720
					images_1024
					videos
					location
					place
					address
					people
					peopleInfo
					bed
					bedInfo
					square
					booking
					rules
					heat
					comforts
					tags
					premium
					publish
					companyId
				}
				AdminTgBot{
					id
					phone
					code
					step
					chatId
					auth
					companyId
				}
			}
		}
	}
`
export const UPDATE_ONE_COMPANY_BILLING = gql`
	mutation(
		$data: CompanyBillingUpdateInput!
		$where: CompanyBillingWhereUniqueInput!
	){
		updateOneCompanyBilling(
			data: $data
			where: $where
		){
			id
			fullName
			shortName
			inn
			kpp
			ogrn
			zip
			city
			street
			email
			lastName
			firstName
			middleName
			phone
			account
			bankName
			bik
			correspAcc
			companyId
			company{
				id
				phone
				contact
				payment
				paymentCardNumber
				paymentType
				email
				name
				legal
				splitId
				comment
				coupons{
					id
					code
					companyId
				}
				objects{
					id
					name
					description
					note
					images
					images_720
					images_1024
					videos
					location
					place
					address
					people
					peopleInfo
					bed
					bedInfo
					square
					booking
					rules
					heat
					comforts
					tags
					premium
					publish
					companyId
				}
				AdminTgBot{
					id
					phone
					code
					step
					chatId
					auth
					companyId
				}
			}
		}
	}
`
export const DELETE_ONE_COMPANY_BILLING = gql`
	mutation(
		$where: CompanyBillingWhereUniqueInput!
	){
		deleteOneCompanyBilling(
			where: $where
		){
			id
			fullName
			shortName
			inn
			kpp
			ogrn
			zip
			city
			street
			email
			lastName
			firstName
			middleName
			phone
			account
			bankName
			bik
			correspAcc
			companyId
			company{
				id
				phone
				contact
				payment
				paymentCardNumber
				paymentType
				email
				name
				legal
				splitId
				comment
				coupons{
					id
					code
					companyId
				}
				objects{
					id
					name
					description
					note
					images
					images_720
					images_1024
					videos
					location
					place
					address
					people
					peopleInfo
					bed
					bedInfo
					square
					booking
					rules
					heat
					comforts
					tags
					premium
					publish
					companyId
				}
				AdminTgBot{
					id
					phone
					code
					step
					chatId
					auth
					companyId
				}
			}
		}
	}
`
export const UPSERT_ONE_COMPANY_BILLING = gql`
	mutation(
		$where: CompanyBillingWhereUniqueInput!
		$create: CompanyBillingCreateInput!
		$update: CompanyBillingUpdateInput!
	){
		upsertOneCompanyBilling(
			where: $where
			create: $create
			update: $update
		){
			id
			fullName
			shortName
			inn
			kpp
			ogrn
			zip
			city
			street
			email
			lastName
			firstName
			middleName
			phone
			account
			bankName
			bik
			correspAcc
			companyId
			company{
				id
				phone
				contact
				payment
				paymentCardNumber
				paymentType
				email
				name
				legal
				splitId
				comment
				coupons{
					id
					code
					companyId
				}
				objects{
					id
					name
					description
					note
					images
					images_720
					images_1024
					videos
					location
					place
					address
					people
					peopleInfo
					bed
					bedInfo
					square
					booking
					rules
					heat
					comforts
					tags
					premium
					publish
					companyId
				}
				AdminTgBot{
					id
					phone
					code
					step
					chatId
					auth
					companyId
				}
			}
		}
	}
`
export const DELETE_MANY_COMPANY_BILLING = gql`
	mutation(
		$where: CompanyBillingWhereInput
	){
		deleteManyCompanyBilling(
			where: $where
		){
			count
		}
	}
`
export const UPDATE_MANY_COMPANY_BILLING = gql`
	mutation(
		$data: CompanyBillingUpdateManyMutationInput!
		$where: CompanyBillingWhereInput
	){
		updateManyCompanyBilling(
			data: $data
			where: $where
		){
			count
		}
	}
`