import React, { useState } from 'react'
import { ScrollView, StyleSheet, Text, TouchableOpacity, View, TextInput, Platform } from 'react-native'

import { useNavigation } from '@react-navigation/native'
import { useQuery, useMutation, useLazyQuery } from '@apollo/client'
import { Ionicons } from '@expo/vector-icons'
import dayjs from 'dayjs'
import 'dayjs/locale/ru'

import { FIND_UNIQUE_REQUEST, UPDATE_ONE_OBJECT, UPDATE_ONE_REQUEST, FIND_FIRST_OBJECT } from '../gqls'

import Loading from '../components/Loading'
import Error from '../components/Error'
import Window from '../components/Window'

dayjs.locale('ru')

const ReviewScreen = ({ route }) => {
    const { id } = route.params
    const navigation = useNavigation()
    const [rating, setRating] = useState(1)
    const [review, setReview] = useState('')
    const [objectId, setObjectId] = useState()
    const now = dayjs()

    const { loading: findUniqueRequestLoading, error: findUniqueRequestError } = useQuery(FIND_UNIQUE_REQUEST, {
        fetchPolicy: 'network-only',
        variables: {
            where: {
                id
            }
        },
        onCompleted: ({ findUniqueRequest }) => {
            if (findUniqueRequest.rating > 0) {
                setRating(findUniqueRequest.rating)
            }
            setObjectId(findUniqueRequest.objectId)
            setReview(findUniqueRequest.review)
        }
    })
    const [findUniqueObjectQuery, { loading: findUniqueObjectLoading, error: findUniqueObjectError }] = useLazyQuery(FIND_FIRST_OBJECT, {
        fetchPolicy: 'network-only',
    })

    const [updateOneRequestMutation] = useMutation(UPDATE_ONE_REQUEST, {
        onCompleted: async () => {
            await findUniqueObjectQuery({
                variables: {
                    where: {
                        id: {
                            equals: objectId
                        }
                    }
                },
                onCompleted: ( {findFirstObject} ) => {
                    const filteredRequests = findFirstObject.requests.filter(req => req.rating != 0)
                    const rating = filteredRequests.map(object => object.rating).reduce((a = 0, b = 0) => a + b, 0) / filteredRequests.length
                    updateOneObjectMutation({
                        variables: {
                            where: {
                                id : findFirstObject.id
                            },
                            data: {
                                rating: rating
                            }
                        },
                    })
                }
            })
        }
    })

    const [updateOneObjectMutation] = useMutation(UPDATE_ONE_OBJECT, {
        onCompleted: () => {
            if (navigation.canGoBack()) {
                navigation.goBack()
            } else {
                window.history.back()
            }
        }
    })

    if (findUniqueRequestLoading) return <Loading />

    if (findUniqueRequestError) return <Error />

    const onReview = async () => {
        if (!review) return
        await updateOneRequestMutation({
            variables: {
                where: {
                    id
                },
                data: {
                    rating: {
                        set: rating
                    },
                    review: {
                        set: review
                    },
                    ratingAt: {
                        set: now.format('YYYY-MM-DD')
                    }
                }
            },
        })
    }

    return (
        <>
            <ScrollView style={styles.container} contentContainerStyle={{ marginTop: 16 }}>
                <Window style={{ paddingHorizontal: 16 }}>
                    <View style={styles.group}>
                        <Text style={styles.title}>Оценка</Text>
                        <View style={styles.rateContainer}>
                            {
                                [1, 2, 3, 4, 5].map((object, index) => (
                                    <TouchableOpacity activeOpacity={1} onPress={() => setRating(object)} key={index}>
                                        <Ionicons name='ios-star' size={28} color={rating >= object ? '#E6B762' : '#D0D5DD'} />
                                    </TouchableOpacity>
                                ))
                            }
                        </View>
                    </View>
                    <View style={styles.group}>
                        <Text style={styles.title}>Отзыв</Text>
                        <TextInput multiline={true} value={review} onChangeText={(value) => setReview(value)} style={styles.input} placeholder='Введите отзыв' placeholderTextColor='#667085' />
                    </View>
                </Window>
            </ScrollView>
            <Window>
                <View style={styles.footer}>
                    <TouchableOpacity onPress={onReview} style={styles.button}>
                        <Text style={styles.buttonText}>Отправить</Text>
                    </TouchableOpacity>
                </View>
            </Window>
        </>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1
    },
    title: {
        fontSize: 16,
        marginBottom: 12,
        color: '#101828',
        fontWeight: '500'
    },
    group: {
        marginBottom: 12
    },
    button: {
        justifyContent: 'center',
        alignItems: 'center',
        height: 48,
        borderRadius: 8,
        backgroundColor: '#E6B762',
    },
    buttonText: {
        color: '#FCFCFD',
        fontWeight: '500'
    },
    footer: {
        borderTopWidth: 1,
        borderColor: '#D0D5DD',
        padding: 16
    },
    rateContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        gap: 4
    },
    input: {
        height: 120,
        textAlignVertical: 'top',
        borderWidth: 1,
        borderColor: '#D0D5DD',
        borderRadius: 4,
        padding: 10,
        marginBottom: 16,
    },
})

export default ReviewScreen