import React, { useState } from 'react'
import { ScrollView, StyleSheet, Text, TouchableOpacity, TextInput, View, Linking, Image, Dimensions, Modal, PanResponder } from 'react-native'
import { Switch } from 'react-native-switch-ios'
import { useQuery, useMutation, useLazyQuery } from '@apollo/client'
import { useNavigation } from '@react-navigation/native'
import { toast } from 'react-toastify'
import { Ionicons } from '@expo/vector-icons'
import IMask from 'imask'
import dayjs from 'dayjs'
import 'dayjs/locale/ru'

import Loading from '../components/Loading'
import Error from '../components/Error'
import Auth from '../components/Auth'
import Window from '../components/Window'

import { logoBase64 } from '../utils/const'
import { useUser } from '../hooks/user'

import { reservationsVar } from '../utils/cache'
import QRCode from 'react-native-qrcode-svg';

import { FIND_UNIQUE_SCHEDULE, CREATE_ONE_REQUEST, PAYMENT_COMPANY, FIND_UNIQUE_OBJECT, FIND_MANY_REQUEST } from '../gqls'

const emailMask = IMask.createMask({
    mask: (value) => {
        if (/^[a-z0-9_\.-]+$/.test(value)) {
            return true
        }
        if (/^[a-z0-9_\.-]+@$/.test(value)) {
            return true
        }
        if (/^[a-z0-9_\.-]+@[a-z0-9-]+$/.test(value)) {
            return true
        }
        if (/^[a-z0-9_\.-]+@[a-z0-9-]+\.$/.test(value)) {
            return true
        }
        if (/^[a-z0-9_\.-]+@[a-z0-9-]+\.[a-z]{1,4}$/.test(value)) {
            return true
        }
        if (/^[a-z0-9_\.-]+@[a-z0-9-]+\.[a-z]{1,4}\.$/.test(value)) {
            return true
        }
        if (/^[a-z0-9_\.-]+@[a-z0-9-]+\.[a-z]{1,4}\.[a-z]{1,4}$/.test(value)) {
            return true
        }
        return false
    }
})

const ConfirmScreen = ({ route }) => {
    const { scheduleId, objectId, selectedDate } = route.params
    const navigation = useNavigation()
    const windowWidth = Dimensions.get('window').width;
    const isMobile = windowWidth <= 1024;

    // const [bonus, setBonus] = useState(false)
    const [cancel, setCancel] = useState(false)
    const [phone, setPhone] = useState('')
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [people, setPeople] = useState('')
    const [comment, setComment] = useState('')
    const [loading, setLoading] = useState(false)
    const [visibleModalMobile, setVisibleModalMobile] = useState(false);
    const [visibleModalDesktop, setVisibleModalDesktop] = useState(false);
    const [offset, setOffset] = useState(0);
    const [textQRcode, setTextQRcode] = useState('');
    var clickAddRequest = false

    const remain = dayjs(selectedDate).diff(dayjs(), 'day')

    const { user, loading: userLoading, error: userError } = useUser({
        onCompleted: ({ findCurrentUser }) => {
            setPhone(findCurrentUser.phone)
            if (findCurrentUser.name) setName(findCurrentUser.name)
            if (findCurrentUser.email) setEmail(findCurrentUser.email)
        }
    })

    const { data: findUniqueScheduleData, loading: findUniqueScheduleLoading, error: findUniqueScheduleError } = useQuery(FIND_UNIQUE_SCHEDULE, {
        fetchPolicy: 'network-only',
        variables: {
            where: {
                id: scheduleId
            }
        }
    })

    const [findUniqueRequestData, { data: findRequestData, loading: findUniqueRequestLoading, error: findUniqueRequestError }] = useLazyQuery(FIND_MANY_REQUEST)

    const { data: findUniqueObjectData, loading: findUniqueObjectLoading, error: findUniqueObjectError } = useQuery(FIND_UNIQUE_OBJECT, {
        fetchPolicy: 'network-only',
        variables: {
            where: {
                id: objectId
            }
        }
    })

    const [paymentCompanyMutation, { data: paymentCompanyData }] = useMutation(PAYMENT_COMPANY, {
        onCompleted: ({ paymentCompany }) => {
            Linking.openURL(paymentCompany)
        }
    })

    const [createOneRequestMutation, { loading: createOneRequestLoading, data: createOneRequestData }] = useMutation(CREATE_ONE_REQUEST, {
        onCompleted: async () => {
            setLoading(false)
            reservationsVar(reservationsVar() + 1)
            toast('Бронь успешно создана, оплатите бронь')
        }
    })

    if (findUniqueScheduleLoading || userLoading || findUniqueObjectLoading) {
        return <Loading />
    }

    if (userError) {
        return <Auth />
    }

    if (findUniqueScheduleError || findUniqueObjectError) {
        return <Error />
    }

    const { findUniqueSchedule } = findUniqueScheduleData
    const { findUniqueObject } = findUniqueObjectData

    const createOneRequest = createOneRequestData?.createOneRequest
    const panResponder = PanResponder.create({
        onStartShouldSetPanResponder: () => true,
        onPanResponderMove: (evt, gestureState) => {
            setOffset(gestureState.dy);
        },
        onPanResponderRelease: (evt, gestureState) => {
            console.log(gestureState.dy)
            // if (gestureState.dy > 0) {
            setVisibleModalMobile(false);
            setVisibleModalDesktop(false);
            // }
        },
    });
    const onRequest = async () => {
        if (!name || !phone || !email || !people) {
            if (!name) {
                toast('Введите имя')
                return
            }
            if (!phone) {
                toast('Введите номер телефона')
                return
            }
            if (!email) {
                toast('Введите электронную почту')
                return
            }
            if (!people) {
                toast('Введите количество гостей')
                return
            }
            return
        }
        if (parseInt(people) > findUniqueObject.people) {
            toast('Превышено максимальное количество гостей '+findUniqueObject.people)
            return
        }
        setLoading(true)
        var findUniqueRequestDataPromise = findUniqueRequestData({
            fetchPolicy: 'network-only',
            variables: {
                where: {
                    objectId: {
                        equals: objectId
                    },
                    reservedAt: {
                        equals: selectedDate
                    },
                    OR: [{
                        status: {
                            equals: '0'
                        },
                    }, {
                        status: {
                            equals: '1'
                        },
                    }]
                }
            }
        })

        findUniqueRequestDataPromise.then(function (result) {
            if (result.data.findManyRequest.length == 0 && clickAddRequest == false) {
                createOneRequestMutation({
                    variables: {
                        data: {
                            reservedAt: selectedDate,
                            status: '0',
                            name,
                            phone,
                            email,
                            people,
                            comment,
                            schedule: {
                                connect: {
                                    id: scheduleId
                                }
                            },
                            object: {
                                connect: {
                                    id: objectId
                                }
                            },
                            user: {
                                connect: {
                                    id: user.id
                                }
                            },
                            price: findUniqueSchedule.price,
                            discount: findUniqueSchedule.discount,
                            cancel,
                            reserve: findUniqueSchedule.reserve
                        }
                    }
                })
                clickAddRequest = true
            } else {
                let addRequest = true;
                if (result.data.findManyRequest.length > 1) {
                    addRequest = false;
                }
                const request = result.data.findManyRequest[0];
                
                if (request.schedule.fullday) {
                    addRequest = false
                }

                if (findUniqueSchedule.closes.some(close => close === dayjs(selectedDate).format('DD.MM.YYYY'))) {
                    addRequest = false
                }

                if (findUniqueSchedule.fullday) {
                    addRequest = false
                }

                if (findUniqueSchedule.id == request.scheduleId) {
                    addRequest = false
                }
                
                if (!addRequest) {
                    setLoading(false)
                    toast("Данный тариф занят")
                } else {
                    createOneRequestMutation({
                        variables: {
                            data: {
                                reservedAt: selectedDate,
                                status: '0',
                                name,
                                phone,
                                email,
                                people,
                                comment,
                                schedule: {
                                    connect: {
                                        id: scheduleId
                                    }
                                },
                                object: {
                                    connect: {
                                        id: objectId
                                    }
                                },
                                user: {
                                    connect: {
                                        id: user.id
                                    }
                                },
                                price: findUniqueSchedule.price,
                                discount: findUniqueSchedule.discount,
                                cancel,
                                reserve: findUniqueSchedule.reserve
                            }
                        }
                    })
                    clickAddRequest = true
                }
            }
        });
    }
    if (loading) return <Loading />

    const onPayment = async () => {
        const legal = createOneRequest?.object?.company?.legal
        if (legal) {
            // if (paymentCompanyData && paymentCompanyData.paymentCompany) {
            //     const paymentCompany = paymentCompanyData.paymentCompany
            //     Linking.openURL(paymentCompany)
            // } else {
            //     let amount = findUniqueSchedule.reserve
            //     if (cancel) {
            //         amount += findUniqueSchedule.cancel
            //     }
            //     await paymentCompanyMutation({
            //         variables: {
            //             data: {
            //                 companyId: createOneRequest.object.company.id,
            //                 requestId: createOneRequest.id,
            //                 amount: amount.toString()
            //             }
            //         }
            //     })
            // }
            const companyBilling = createOneRequest?.object?.company?.CompanyBilling
            if (companyBilling.length > 0) {
                const companyBillingInfo = companyBilling[0];
                setTextQRcode(`ST00012|Name=${companyBillingInfo.fullName}|PersonalAcc=${companyBillingInfo.account}|BankName=${companyBillingInfo.bankName}|BIC=${companyBillingInfo.bik}|CorrespAcc=${companyBillingInfo.correspAcc}|Sum=${createOneRequest.reserve}00|SumRub=${createOneRequest.reserve}|Purpose=Предоплата за бронирование ${createOneRequest?.object?.name}|PayeeINN=${companyBillingInfo.inn}`);
                if (isMobile)
                    setVisibleModalMobile(true)
                else
                    setVisibleModalDesktop(true)
            }
        } else {
            if (createOneRequest?.object?.company?.paymentType == 0) {
                const phone = createOneRequest?.object?.company?.payment
                navigator.clipboard.writeText(phone)
                toast('Номер скопирован, оплатите по номеру телефона')
            } else {
                const paymentCardNumber = createOneRequest?.object?.company?.paymentCardNumber
                navigator.clipboard.writeText(paymentCardNumber.toString())
                toast('Номер карты скопирован, оплатите по номеру банковской карты')
            }
        }
    }

    const onChangeEmail = (value) => {
        const maskedValue = emailMask.resolve(value)
        setEmail(maskedValue)
    }
    if (createOneRequest) {
        return (
            <Window style={[styles.container, { paddingHorizontal: 16 }]}>
                <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                    <Ionicons name='checkmark-circle-outline' size={128} color='#22c55e' />
                    <Text style={[styles.title, { marginVertical: 8 }]}>Ваша заявка была принята</Text>
                </View>
                {
                    createOneRequest?.object?.company?.legal == true ? null :
                        findUniqueSchedule?.object?.company?.comment ? (
                            <View>
                                <Text style={[styles.title, { marginVertical: 0 }]}>Способ оплаты</Text>
                                <Text style={[styles.total, { fontSize: 16, fontWeight: '500', marginTop: 4 }]}>{findUniqueSchedule.object.company.comment}</Text>
                            </View>
                        ) : null
                }
                <View style={styles.line} />
                <Text style={styles.text}>Для подтверждения брони необходимо внести предоплату. В случае отсутствия предоплаты Ваша бронь отменяется.</Text>
                <TouchableOpacity onPress={() => navigation.navigate('ProfileStack', { screen: "Reservation", params: { id: createOneRequest.id } })} style={styles.button}>
                    <Text style={styles.buttonText}>К брони</Text>
                </TouchableOpacity>
                <TouchableOpacity onPress={onPayment} style={[styles.button, { backgroundColor: '#BCC8B2', marginBottom: 24, marginTop: 12 }]}>
                    <Text style={styles.buttonText}>Оплатить{createOneRequest?.object?.company?.legal === false ? (createOneRequest?.object?.company?.paymentType == 0 ? ` по номеру телефона +${createOneRequest?.object?.company?.payment}` : ` по номеру банковской карты ${createOneRequest?.object?.company?.paymentCardNumber}`) : ' по QR коду'}</Text>
                </TouchableOpacity>
                <Modal
                    visible={visibleModalDesktop}
                    animationType="fade"
                    transparent={true}
                >
                    <ScrollView>
                        <View style={styles.centeredView}>
                            <View style={styles.modalView}>
                                <View style={styles.modalComfortsClose}>
                                    <Text style={styles.titleText}>Отсканируйте QR код</Text>
                                    <TouchableOpacity style={styles.modalComfortsCloseBtn} onPress={() => setVisibleModalDesktop(false)}>
                                        {windowWidth > 768 ? (
                                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                                <path d="M8 8L16 16M16 16L24 24M16 16L24 8M16 16L8 24" stroke="#6B7280" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>
                                        ) : (
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                <path d="M12.4999 15L7.49994 10L12.4999 5" stroke="black" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>
                                        )}
                                    </TouchableOpacity>
                                </View>
                                <View style={{
                                    flex: 1,
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}>
                                    <QRCode
                                        value={`${textQRcode}`}
                                        logo={{ uri: logoBase64 }}
                                        logoSize={60}
                                        size={300}
                                        logoBackgroundColor='transparent'
                                    />
                                </View>
                            </View>
                        </View>
                    </ScrollView>
                </Modal>
                <Modal
                    visible={visibleModalMobile}
                    transparent={true}
                    onRequestClose={() => setVisibleModalMobile(false)}
                >
                    <View style={styles.overlay} />
                    <View
                        style={[styles.modalContent, { transform: [{ translateY: offset }] }]}
                        {...panResponder.panHandlers}
                    >
                        <View style={styles.closeLine} />
                        <View style={{
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}>
                            <Text style={styles.titleText}>Отсканируйте QR код</Text>
                            <Text style={styles.descriptionText}>Сохраните QR-код: сделайте скриншот экрана с отображенным QR-кодом и оплатите в приложении вашего банка</Text>
                        </View>
                        <View style={{
                            flex: 1,
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginTop: 20, marginBottom: 20
                        }}>
                            <QRCode
                                value={`${textQRcode}`}
                                logo={{ uri: logoBase64 }}
                                logoSize={60}
                                size={300}
                                logoBackgroundColor='transparent'
                            />
                        </View>
                    </View>
                </Modal>
            </Window>
        )
    }

    return (
        <ScrollView style={styles.container}>
            <Window style={{ paddingHorizontal: 16 }}>
                <Text style={styles.title}>Контактная информация</Text>
                <TextInput value={name} onChangeText={(value) => setName(value)} style={styles.input} placeholder='Имя' placeholderTextColor='#667085' />
                <TextInput value={email} onChangeText={onChangeEmail} style={styles.input} placeholder='Электронная почта' placeholderTextColor='#667085' />
                <TextInput value={phone} onChangeText={(value) => setPhone(value)} style={styles.input} placeholder='Номер телефона' placeholderTextColor='#667085' />
                <TextInput value={people} onChangeText={(value) => setPeople(value)} style={styles.input} placeholder='Количество гостей' placeholderTextColor='#667085' />
                <View style={[styles.input, { height: 100, paddingVertical: 10 }]}>
                    <TextInput value={comment} onChangeText={(value) => setComment(value)} style={{ flex: 1 }} multiline placeholder='Комментарий' placeholderTextColor='#667085' />
                </View>
                {/* <TextInput style={styles.input} placeholder='Промокод' placeholderTextColor='#667085' /> */}
                {/* <View style={styles.bonusContainer}>
                <View>
                    <Text style={[styles.title, { marginVertical: 0, marginBottom: 2 }]}>Бонусы</Text>
                    <Text style={styles.text}>У вас {user.points} бонусов</Text>
                </View>
                <Switch
                    inactiveBgColor='#D0D5DD'
                    activeBgColor='#BCC8B2'
                    isOn={bonus}
                    onToggle={() => setBonus(prev => !prev)}
                />
            </View> */}
                {
                    findUniqueSchedule.cancel && remain > 2 ? (
                        <View style={styles.bonusContainer}>
                            <View>
                                <Text style={[styles.title, { marginVertical: 0, marginBottom: 2 }]}>С отменой до {dayjs(selectedDate).subtract(3, 'day').format('DD.MM.YYYY')} включительно</Text>
                                <Text style={styles.text}>Стоимость {findUniqueSchedule.cancel} ₽</Text>
                            </View>
                            <Switch
                                inactiveBgColor='#D0D5DD'
                                activeBgColor='#BCC8B2'
                                isOn={cancel}
                                onToggle={() => setCancel(prev => !prev)}
                            />
                        </View>
                    ) : null
                }
                {/* <View style={styles.checkContainer}>
                <Text style={styles.text}>Промокод</Text>
                <Text style={[styles.title, { marginVertical: 0, marginBottom: 0 }]}>-500 ₽</Text>
            </View> */}
                <View style={[styles.checkContainer, { alignItems: 'flex-end' }]}>
                    <View>
                        <Text style={styles.text}>{findUniqueObject.name}</Text>
                        <Text style={styles.text}>{findUniqueSchedule.name}</Text>
                        <Text style={styles.text}>{dayjs(selectedDate).format('DD.MM.YYYY')} с {findUniqueSchedule.startAt} до {findUniqueSchedule.endAt}</Text>
                    </View>
                    {findUniqueSchedule.discount && findUniqueSchedule.days.includes(dayjs(selectedDate).format('DD.MM.YYYY')) && findUniqueSchedule.price > findUniqueSchedule.discount ? (
                        <View style={{ flexDirection: 'row' }}>
                            <Text style={[styles.title, { textDecorationLine: 'line-through', color: '#667085' }]}>{findUniqueSchedule.price} ₽</Text>
                            <Text style={[styles.title]}> {findUniqueSchedule.discount} ₽</Text>
                        </View>
                    ) : (
                        <Text style={[styles.title]}>{findUniqueSchedule.discount && findUniqueSchedule.days.includes(dayjs(selectedDate).format('DD.MM.YYYY')) || findUniqueSchedule.price} ₽</Text>
                    )}
                </View>
                {/* {
                    cancel ? (
                        <View style={styles.checkContainer}>
                            <Text style={[styles.title, { marginVertical: 0 }]}>С отменой</Text>
                            <Text style={styles.total}>+ {findUniqueSchedule.cancel} ₽</Text>
                        </View>
                    ) : (
                        <View style={styles.checkContainer}>
                            <Text style={[styles.title, { marginVertical: 0 }]}>Без отмены</Text>
                            <Text style={styles.total}>+ 0 ₽</Text>
                        </View>
                    )
                } */}
                <View style={styles.line} />
                <View style={styles.checkContainer}>
                    <Text style={[styles.title, { marginVertical: 0 }]}>Всего к оплате</Text>
                    {/* <Text style={styles.total}>{bonus ? (findUniqueSchedule.discount || findUniqueSchedule.price) - user.points : (findUniqueSchedule.discount || findUniqueSchedule.price)} ₽</Text> */}
                    <Text style={styles.total}>{cancel ? (findUniqueSchedule.discount || findUniqueSchedule.price) + findUniqueSchedule.cancel : findUniqueSchedule.discount || findUniqueSchedule.price} ₽</Text>
                </View>
                <View style={styles.checkContainer}>
                    <Text style={[styles.title, { marginVertical: 0 }]}>Оплата для бронирования</Text>
                    {/* <Text style={styles.total}>{bonus ? findUniqueSchedule.reserve - user.points : findUniqueSchedule.reserve} ₽</Text> */}
                    <Text style={styles.total}>{cancel ? findUniqueSchedule.reserve + findUniqueSchedule.cancel : findUniqueSchedule.reserve} ₽</Text>
                </View>
                <View style={styles.line} />
                <Text style={styles.text}>Для подтверждения брони необходимо внести оплату для бронирования. В случае отсутствия оплаты Ваша бронь отменяется.</Text>
                <TouchableOpacity onPress={onRequest} disabled={createOneRequestLoading} style={[styles.button, { marginBottom: 24 }]}>
                    <Text style={styles.buttonText}>Подтвердить</Text>
                </TouchableOpacity>
            </Window>
        </ScrollView >
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1
    },
    title: {
        marginVertical: 24,
        color: '#101828',
        fontWeight: '500',
        fontSize: 16
    },
    input: {
        height: 44,
        borderWidth: 1,
        borderColor: '#D0D5DD',
        borderRadius: 4,
        paddingHorizontal: 10,
        marginBottom: 16,
    },
    paymentContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    paymentType: {
        color: '#101828'
    },
    bonusContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: 12,
        marginTop: 8
    },
    text: {
        color: '#101828',
        fontWeight: '500',
        fontSize: 14
    },
    checkContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: 8
    },
    line: {
        height: 1,
        backgroundColor: '#D0D5DD',
        marginVertical: 16
    },
    total: {
        color: '#101828',
        fontWeight: '500',
        fontSize: 20
    },
    button: {
        justifyContent: 'center',
        alignItems: 'center',
        height: 48,
        borderRadius: 8,
        backgroundColor: '#E6B762',
        marginTop: 24
    },
    buttonText: {
        color: '#101828',
        fontWeight: '500'
    },
    userError: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center'
    },
    centeredView: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 10,
        position: 'relative',
    },
    modalView: {
        width: 400,
        height: 400,
        marginVertical: (Dimensions.get('window').height - 400) / 2,
        backgroundColor: '#FFFFFF',
        borderRadius: 10,
        padding: 20,
    },
    overlay: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    modalContent: {
        width: Dimensions.get('window').width,
        height: Dimensions.get('window').height / 2,
        backgroundColor: '#FFFFFF',
        borderRadius: 10,
        zIndex: 10,
        padding: 20,
        position: 'absolute',
        bottom: 0,
        left: 0,

    },
    titleText: {
        fontSize: 18,
        fontWeight: 'bold',
        marginRight: 10,
    },
    descriptionText: {
        fontSize: 16,
        textAlign: 'center',
        color: '#6A6A6A',
        marginRight: 10,
        marginTop: 10
    },
    modalComfortsCloseBtn: {
        position: 'absolute',
        top: 5,
        right: 10,
        fontSize: 20,
        fontWeight: 'bold',
    },
})

export default ConfirmScreen